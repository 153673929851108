import React from "react";
import { useDispatch, useSelector } from "react-redux";

import styles from "./header.module.css";
import getWindowDimensions from "../../../hooks/useWindowDimensions";
import { controlActions } from "../../../store/controls/controls";
import { regSw, subscribe } from "../../../helpers/utils";

const Header = () => {
  const { sidebar } = useSelector((state) => state.control);
  const { width } = getWindowDimensions();
  const dispatch = useDispatch();
  const authState = useSelector((state) => state.auth.loggedIn);

  const { user } = useSelector((state) => state.auth);

  const handlebar = () => {
    width < 738 && dispatch(controlActions.setSidebar(!sidebar));
  };
  const { balance } = useSelector((state) => state.auth);

  const registerAndSubscribe = async () => {
    try {
      const serviceWorkerReg = await regSw();
      await subscribe(serviceWorkerReg, dispatch, authState);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <header className={styles.header}>
        <div>
          <h2>Welcome, {user.username ? user.username : "John Doe"} 👋🏾</h2>

          {(!user.name || !user.wallet) && (
            <p className={styles.warning}>
              Update your information on the settings page to continue using our
              platform
            </p>
          )}

          {user.blacklist_id && (
            <p className={styles.warning}>
              Your account has been suspended because {user.reason}
            </p>
          )}
        </div>

        <img
          onClick={handlebar}
          className={width < 738 ? styles.small : undefined}
          src={
            width < 738
              ? require(`../../../assets/${sidebar ? "close" : "ham"}.png`)
              : require("../../../assets/piicon.png")
          }
          alt="pi icon"
        />
      </header>
      {!balance.endpoint && (
        <div className={styles.notification}>
          <button onClick={registerAndSubscribe}>Activate Notification</button>
          <p>
            For you to receive real-time notification about your orders, click
            the activate: iOS support soon
          </p>
        </div>
      )}
    </>
  );
};

export default Header;
