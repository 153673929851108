import React from "react";

import styles from "./header.module.css";
import { useNavigate } from "react-router";
const Header = ({ active, setactive }) => {
  const navigate = useNavigate();
  return (
    <div className={styles.container}>
      <div>
        <button
          onClick={() => setactive("sell")}
          className={active === "sell" ? styles.active : undefined}
        >
          Buy
        </button>
        <button
          onClick={() => setactive("buy")}
          className={active === "buy" ? styles.active : undefined}
        >
          Sell
        </button>
      </div>
      <div onClick={() => navigate("/account/orders/history")}>
        <img src={require("../../../../../assets/history.png")} alt="history" />
        <p>History</p>
      </div>
    </div>
  );
};

export default Header;
