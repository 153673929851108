import React from "react";
import { Navigate, Route, Routes } from "react-router";

import Dashboard from "../../components/account/dashboard/dashboard";
import Sidebar from "../../components/account/sidebar/sidebar";
import Orders from "../../components/account/orders/orders";
import Ads from "../../components/account/ads/ads";
import Settings from "../../components/account/settings/settings";

import styles from "./account.module.css";
import Header from "../../components/account/header/header";

const Account = () => {
  return (
    <div className={styles.container}>
      <Sidebar />
      <div className={styles.authbg}>
        <Header />
        <Routes>
          <Route path="dashboard" exact element={<Dashboard />} />
          <Route path="orders/*" exact element={<Orders />} />
          <Route path="ads/*" exact element={<Ads />} />
          <Route path="settings" exact element={<Settings />} />
          <Route
            path={"*"}
            element={<Navigate replace to="/account/dashboard" />}
          />
        </Routes>
        <hr />
      </div>
    </div>
  );
};

export default Account;
