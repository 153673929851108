import React from "react";
import StarRatings from "react-star-ratings";

import numberWithCommas from "../../../../../helpers/commaSeperator";

import styles from "./ordersCard.module.css";
import { useNavigate } from "react-router";

const OrdersCard = ({ data }) => {
  function numberToTwoDecimals(num) {
    if (num) {
      var with2Decimals = num.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
      return Number(with2Decimals);
    } else {
      return 0;
    }
  }
  const navigate = useNavigate();
  return (
    <div className={styles.container}>
      <div>
        <span>Price</span>
        <p>{data.price} USDT</p>
      </div>
      <div className={styles.merchant}>
        <span>Merchant</span>
        <div>
          <div>
            <p>{data.username}</p>
            <StarRatings
              rating={data.rating ? Number(data.rating) : 0}
              starRatedColor="#75149F"
              numberOfStars={5}
              name="rating"
              starDimension="13px"
              starSpacing="2px"
            />
          </div>
          <div className={styles.stats}>
            <div>
              <span>Completion</span>
              <p>
                {data.completion ? numberToTwoDecimals(data.completion) : 100}%
              </p>
            </div>
            <div>
              <span>Trades</span>
              <p>{numberWithCommas(data.trades)}</p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <span>Min {data.type.toLowerCase() === "sell" ? "Buy" : "Sell"}</span>
        <div className={styles.min}>
          <p>{data.min}</p>
          <span>Pi</span>
        </div>
      </div>
      <div>
        <span>Available</span>
        <p>
          {numberWithCommas(data.available)}{" "}
          {data.type.toLowerCase() === "sell" ? "Pi" : "USDT"}
        </p>
      </div>
      <div>
        <button
          onClick={() => navigate(`/account/orders/trade/${data.ad_id}`)}
          className={
            data.type.toLowerCase() === "sell" ? styles.buy : styles.sell
          }
        >
          {data.type.toLowerCase() === "sell" ? "Buy" : "Sell"}
        </button>
      </div>
    </div>
  );
};

export default OrdersCard;
