import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import styles from "./withdraw.module.css";
import { controlActions } from "../../../../store/controls/controls";
import { DropDown } from "../dropDown/dropDown";
import { createWithdrawal, getWithdrawalCode } from "../../accounthelper";
import { useNavigate } from "react-router";
import useOutsideAlerter from "../../../../hooks/useOutsideClick";
const Withdraw = () => {
  const { withdrawal } = useSelector((state) => state.control);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authState = useSelector((state) => state.auth.loggedIn);

  const ref = useRef(null);

  useOutsideAlerter(ref, ref, () =>
    dispatch(controlActions.setWithdrawal(false))
  );

  const handleWithdraw = (e) => {
    e.preventDefault();
    const form = new FormData(e.target);
    createWithdrawal(dispatch, navigate, form, authState);
  };

  const [counter, setCounter] = useState(0);
  const [genCode, setGenCode] = useState(true);

  const getCode = () => {
    if (genCode) {
      setCounter(122);
      setGenCode(false);
      getWithdrawalCode(dispatch, authState);
    }
  };

  useEffect(() => {
    const interval = setInterval((ms) => {
      setCounter((prevState) => prevState - 1);
      if (counter < 1) {
        setGenCode(true);
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [counter, genCode]);

  return (
    <div
      ref={ref}
      className={`${styles.withdraw} ${withdrawal ? styles.open : undefined}`}
    >
      <div className={styles.container}>
        <img
          onClick={() => dispatch(controlActions.setWithdrawal(false))}
          src={require("../../../../assets/not-check.png")}
          alt="cancel"
        />
        <h4>Withdraw USDT</h4>
        <DropDown type="Select network" data={["Bep20"]} />
        <form onSubmit={handleWithdraw}>
          <label>
            Wallet Address
            <input type="text" name="receiver" />
          </label>
          <label>
            Amount
            <input type="tel" name="amount" />
          </label>
          <label>
            Email Verification
            <div>
              <input type="tel" name="token" />
              <button
                disabled={!genCode}
                className={styles.code}
                type="button"
                onClick={getCode}
              >
                Get OTP {counter > 0 ? counter : ""}
              </button>
            </div>
          </label>
          <div className={styles.note}>
            <span>Important</span>
            <ul>
              <li>
                Send only <strong>USDT</strong> to the correct etwork as we will
                not be responsible for any loss of funds.
              </li>
              <li>
                You will be charged a flat fee of <strong>2USDT</strong>
              </li>
            </ul>
          </div>
          <input type="submit" value="Withdraw USDT" />
        </form>
      </div>
    </div>
  );
};

export default Withdraw;
