import React, { useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import StarRatings from "react-star-ratings";
import "react-confirm-alert/src/react-confirm-alert.css";

import styles from "../singleOrder.module.css";
import { useNavigate } from "react-router";
import CountdownTimer from "../countdownTimer/countdownTimer";
import { useDispatch, useSelector } from "react-redux";
import { genAppeal, piReceived, rateOrder } from "../../ordershelper";
import { confirmAlert } from "react-confirm-alert";

const Buy = ({ data, merch = false }) => {
  function numberToTwoDecimals(num) {
    if (num) {
      var with2Decimals = num.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
      return Number(with2Decimals);
    } else {
      return 0;
    }
  }
  const navigate = useNavigate();
  const [appealState, setAppealState] = useState(false);
  const tenMins = new Date(data.date).getTime() + 600000;
  const appealTime = new Date(data.last_updated).getTime() + 600000;
  const dispatch = useDispatch();
  const { refresh } = useSelector((state) => state.notification);

  const authState = useSelector((state) => state.auth.loggedIn);

  //handler
  const handleChangeRating = async (rating) => {
    if ((!merch && !data.merchant_rating) || (merch && !data.user_rating)) {
      rateOrder(dispatch, refresh, data.order_id, rating, authState);
    }
  };

  //handler
  const handlePiReceived = async () => {
    confirmAlert({
      title: "Confirm Receive",
      message: "Are you sure you have received the exact Pi amount?",
      buttons: [
        {
          label: "Yes",
          onClick: async () =>
            await piReceived(dispatch, refresh, data.order_id, authState),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  //handler
  const createAppeal = async () => {
    appealState &&
      (await genAppeal(dispatch, refresh, data.order_id, authState));
  };

  const orderStart = (
    <div className={styles.details}>
      <div>
        <div>
          <div>
            <span>Amount Deducted</span>
            <p>
              {numberToTwoDecimals(
                data.usdt_amount + (merch ? data.merchant_fee : data.user_fee)
              )}{" "}
              USDT
            </p>
          </div>
          <div>
            <span>Amount to Receive</span>
            <p>
              {data.pi_amount}
              Pi
            </p>
          </div>
        </div>
        <div>
          <span>Your Wallet Address</span>
          <div className={styles.copy}>
            <p>
              ...
              {merch
                ? data.merchant_wallet.slice(data.merchant_wallet.length - 15)
                : data.user_wallet.slice(data.user_wallet.length - 15)}
            </p>
            <CopyToClipboard
              text={merch ? data.merchant_wallet : data.user_wallet}
              onCopy={() => alert("copied")}
            >
              <img src={require("../../../../../assets/copy.png")} alt="copy" />
            </CopyToClipboard>
          </div>
        </div>
        <div className={styles.timer}>
          <span>Pending order will expire in </span>
          <CountdownTimer time={tenMins} text="Order has expired" />
        </div>
      </div>
      <div className={styles.note}>
        <span>Important</span>
        <ul>
          <li>
            Make sure your <strong>Pi Wallet</strong> is mainnet enabled
          </li>
          <li>
            Fee for the transaction is
            <strong> {merch ? data.merchant_fee : data.user_fee} USDT</strong>
          </li>
        </ul>
      </div>
    </div>
  );

  const _piReceived = (
    <div className={styles.details}>
      <div>
        <div>
          <div>
            <span>Amount Deducted</span>
            <p>
              {numberToTwoDecimals(
                data.usdt_amount + (merch ? data.merchant_fee : data.user_fee)
              )}
              USDT
            </p>
          </div>
          <div>
            <span>Amount to Receive</span>
            <p>
              {data.pi_amount}
              Pi
            </p>
          </div>
        </div>
        <div>
          <span>Your Wallet Address</span>
          <div className={styles.copy}>
            <p>
              ...
              {merch
                ? data.merchant_wallet.slice(data.merchant_wallet.length - 15)
                : data.user_wallet.slice(data.user_wallet.length - 15)}
            </p>
            <CopyToClipboard
              text={merch ? data.merchant_wallet : data.user_wallet}
              onCopy={() => alert("copied")}
            >
              <img src={require("../../../../../assets/copy.png")} alt="copy" />
            </CopyToClipboard>
          </div>
        </div>
        <div className={styles.action}>
          <button onClick={handlePiReceived} className={styles.sent}>
            I have received Pi
          </button>
          <button
            onClick={createAppeal}
            className={appealState ? styles.sent : styles.appeal}
          >
            Appeal
          </button>
        </div>
        <div className={styles.timer}>
          <span>You can appeal in</span>
          <CountdownTimer
            time={appealTime}
            text="Appeal can now be filed"
            setState={setAppealState}
          />
        </div>
      </div>
      <div className={styles.note}>
        <span>Important</span>
        <ul>
          <li>
            Only click <strong>I have received Pi</strong> when you have
            confirmed pi amount in your wallet
          </li>
          <li>
            You can appeal this order at the end of the{" "}
            <strong>countdown</strong>
          </li>
          <li>
            Fee for the transaction is
            <strong> {merch ? data.merchant_fee : data.user_fee} USDT</strong>
          </li>
        </ul>
      </div>
    </div>
  );

  const appealStage = (
    <div className={styles.details}>
      <div>
        <div>
          <div>
            <span>Amount to Transfer</span>
            <p>{data.pi_amount} Pi</p>
          </div>
          <div>
            <span>Amount to Receive</span>
            <p>
              {data.usdt_amount}
              USDT
            </p>
          </div>
        </div>
        <div>
          <span>Merchant's Wallet Address</span>
          <div className={styles.copy}>
            <p>
              ...
              {merch
                ? data.merchant_wallet.slice(data.merchant_wallet.length - 15)
                : data.user_wallet.slice(data.user_wallet.length - 15)}
            </p>
            <CopyToClipboard
              text={merch ? data.merchant_wallet : data.user_wallet}
              onCopy={() => alert("copied")}
            >
              <img src={require("../../../../../assets/copy.png")} alt="copy" />
            </CopyToClipboard>
          </div>
        </div>
        <div className={styles.appealStage}>
          <img
            src={require("../../../../../assets/appealprog.png")}
            alt="appeal in progress"
          />
          <p>CS would resolve this momentarily, kindly be patient</p>
        </div>
      </div>
      <div className={styles.note}>
        <span>Important</span>
        <ul>
          <li>
            Appeals are concluded with <strong>30 minutes</strong>
          </li>
          <li>
            CS would confirm the transaction on chain and either{" "}
            <strong>Release</strong> or <strong>Cancel</strong> the trade based
            on chain data
          </li>
        </ul>
      </div>
    </div>
  );
  const cancelled = (
    <div className={styles.details}>
      <div>
        <div>
          <div>
            <span>Amount to Transfer</span>
            <p>{data.pi_amount} Pi</p>
          </div>
          <div>
            <span>Amount to Receive</span>
            <p>
              {data.usdt_amount}
              USDT
            </p>
          </div>
        </div>
        <div>
          <span>Merchant's Wallet Address</span>
          <div className={styles.copy}>
            <p>
              ...
              {merch
                ? data.merchant_wallet.slice(data.merchant_wallet.length - 15)
                : data.user_wallet.slice(data.user_wallet.length - 15)}
            </p>
            <CopyToClipboard
              text={merch ? data.merchant_wallet : data.user_wallet}
              onCopy={() => alert("copied")}
            >
              <img src={require("../../../../../assets/copy.png")} alt="copy" />
            </CopyToClipboard>
          </div>
        </div>
        <div className={styles.ocancelled}>
          <img
            src={require("../../../../../assets/ocancelled.png")}
            alt="cancelled"
          />
        </div>
      </div>
      <div className={styles.note}>
        <span>Important</span>
        <ul>
          <li>
            All fees and amount has been released to your{" "}
            <strong>balance</strong>
          </li>
          <li>
            You can always reach out to CS if you have any questions about this{" "}
            <strong>trade</strong>
          </li>
        </ul>
      </div>
    </div>
  );

  const completed = (
    <div className={styles.details}>
      <div>
        <div>
          <div>
            <span>Amount to Transfer</span>
            <p>{data.pi_amount} Pi</p>
          </div>
          <div>
            <span>Amount to Receive</span>
            <p>
              {data.usdt_amount}
              USDT
            </p>
          </div>
        </div>
        <div>
          <span>Merchant's Wallet Address</span>
          <div className={styles.copy}>
            <p>
              ...
              {merch
                ? data.merchant_wallet.slice(data.merchant_wallet.length - 15)
                : data.user_wallet.slice(data.user_wallet.length - 15)}
            </p>
            <CopyToClipboard
              text={merch ? data.merchant_wallet : data.user_wallet}
              onCopy={() => alert("copied")}
            >
              <img src={require("../../../../../assets/copy.png")} alt="copy" />
            </CopyToClipboard>
          </div>
        </div>
        <div className={styles.rating}>
          <div>
            <p>Review received</p>
            <StarRatings
              rating={
                merch
                  ? data.merchant_rating
                    ? data.merchant_rating
                    : 0
                  : data.user_rating
                  ? data.user_rating
                  : 0
              }
              starRatedColor="#75149F"
              numberOfStars={5}
              name="rating"
              starDimension="18px"
              starSpacing="2px"
            />
          </div>
          <div>
            <p>Make review</p>
            <StarRatings
              rating={
                !merch
                  ? data.merchant_rating
                    ? data.merchant_rating
                    : 0
                  : data.user_rating
                  ? data.user_rating
                  : 0
              }
              starRatedColor="#75149F"
              numberOfStars={5}
              name="rating"
              starDimension="18px"
              starSpacing="2px"
              changeRating={handleChangeRating}
              starHoverColor="#75149F"
            />
          </div>
        </div>
        <div className={styles.ocancelled}>
          <img
            src={require("../../../../../assets/ocompleted.png")}
            alt="completed"
          />
        </div>
      </div>
      <div className={styles.note}>
        <span>Important</span>
        <ul>
          <li>
            You can always reach out to CS if you have any questions about this{" "}
            <strong>trade</strong>
          </li>
        </ul>
      </div>
    </div>
  );

  return (
    <div className={styles.container}>
      <div>
        <div
          onClick={() =>
            navigate(merch ? "/account/ads" : "/account/orders/history")
          }
        >
          <img src={require("../../../../../assets/arrL.png")} alt="arr" />
          <p>Back</p>
        </div>
      </div>

      {data.status === "pending" && data.stage === "start"
        ? orderStart
        : data.appeal === "pending"
        ? appealStage
        : data.status === "pending" && data.stage === "first"
        ? _piReceived
        : data.status === "cancelled"
        ? cancelled
        : data.status === "completed"
        ? completed
        : undefined}
    </div>
  );
};

export default Buy;
