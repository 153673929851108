import React, { useEffect, useState } from "react";

import styles from "./header.module.css";
const Header = ({ active, setactive, category, setCategory }) => {
  const [open, setopen] = useState(false);

  useEffect(() => {
    setopen(false);
  }, [category]);
  return (
    <div className={styles.container}>
      <div>
        <button
          onClick={() => setactive("sell")}
          className={active === "sell" ? styles.active : undefined}
        >
          Buy
        </button>
        <button
          onClick={() => setactive("buy")}
          className={active === "buy" ? styles.active : undefined}
        >
          Sell
        </button>
      </div>
      <div onClick={() => setopen((prevState) => !prevState)}>
        <img src={require("../../../../../assets/lham.png")} alt="ham" />
        <p>{category}</p>
        <img src={require("../../../../../assets/arrD.png")} alt="dropdown" />
        <div className={`${styles.dropdown} ${open ? styles.show : undefined}`}>
          <li
            className={category === "All" ? styles.off : undefined}
            onClick={() => {
              setCategory("All");
            }}
          >
            All
          </li>
          <li
            className={category === "Pending" ? styles.off : undefined}
            onClick={() => setCategory("Pending")}
          >
            Pending
          </li>
          <li
            className={category === "Completed" ? styles.off : undefined}
            onClick={() => setCategory("Completed")}
          >
            Completed
          </li>
          <li
            className={category === "Cancelled" ? styles.off : undefined}
            onClick={() => setCategory("Cancelled")}
          >
            Cancelled
          </li>
        </div>
      </div>
    </div>
  );
};

export default Header;
