import React from "react";
import styles from "./fundSection.module.css";
import Button from "../button/button";
import { SectionWidth } from "../sectionWidth/sectionWidth";

const FundSection = () => {
  return (
    <div className={styles.container}>
      <SectionWidth className={styles.display}>
        <div className={styles.text}>
          <h2>Easy Setup and Funding.</h2>
          <p>
            Signup in less that a minute and get trading already. Your
            personalised is as easy to navigate as conventional dashboards
          </p>
          <Button text="Go to App" link="/account/dashboard" />
        </div>
        <img
          src={require("../../../assets/fundsection.png")}
          alt="fundsection"
        />
      </SectionWidth>
    </div>
  );
};

export default FundSection;
