import React from "react";
import StarRatings from "react-star-ratings";

import numberWithCommas from "../../../../../helpers/commaSeperator";

import styles from "./ordersCard.module.css";
import { useNavigate } from "react-router";

const OrdersCard = ({ data }) => {
  const navigate = useNavigate();
  return (
    <div className={styles.container}>
      <div>
        <span>Price</span>
        <p>{data.price} USDT</p>
      </div>
      <div className={styles.merchant}>
        <span>User</span>
        <div>
          <div>
            <p>{data.username}</p>
            <StarRatings
              rating={data.rating ? Number(data.rating) : 0}
              starRatedColor="#75149F"
              numberOfStars={5}
              name="rating"
              starDimension="13px"
              starSpacing="2px"
            />
          </div>

          <div>
            <span>Trades</span>
            <p>{numberWithCommas(data.trades)}</p>
          </div>
        </div>
      </div>
      <div>
        <span>{data.type.toLowerCase() === "buy" ? "Buying" : "Selling"}</span>
        <div className={styles.min}>
          <p>{data.pi_amount}</p>
          <span>Pi</span>
        </div>
      </div>

      <div>
        <button
          onClick={() => navigate(`/account/orders/id/${data.order_id}`)}
          className={styles.buy}
        >
          View
        </button>
      </div>
    </div>
  );
};

export default OrdersCard;
