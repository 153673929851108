import React from "react";
import { SectionWidth } from "../../components/home/sectionWidth/sectionWidth";
import Header from "../../components/home/header/header";
import Hero from "../../components/home/hero/hero";
import FundSection from "../../components/home/fundSection/fundSection";
import VerificationSection from "../../components/home/verificationSection/verificationSection";
import TradeSection from "../../components/home/tradeSection/tradeSection";
import LightSection from "../../components/home/lightSection/lightSection";
import Faq from "../../components/home/faq/faq";
import Footer from "../../components/footer/footer";

const Home = () => {
  return (
    <>
      <SectionWidth>
        <Header />
        <Hero />
      </SectionWidth>

      <FundSection />
      <VerificationSection />
      <TradeSection />
      <LightSection />
      {/* <ReviewSection /> */}
      <Faq />
      <Footer />
    </>
  );
};

export default Home;
