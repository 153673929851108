//import { authSliceActions } from "../../../store/auth/auth";
import { apiRequest } from "../../../helpers/connections";
import { notificationActions } from "../../../store/notification/notification";

export const getAllAds = async (dispatch, auth) => {
  try {
    dispatch(notificationActions.setNotify(true));

    const data = await apiRequest("ads", undefined, undefined, auth);
    dispatch(notificationActions.setNotify(false));

    return data.ads;
  } catch (error) {
    dispatch(notificationActions.setNotify(false));
    if (error?.info?.error?.status === 422) {
      dispatch(notificationActions.setMessage(error?.info?.error?.message));
    } else {
      dispatch(notificationActions.setMessage("Something went wrong"));
    }
  }
};

export const createOrder = async (dispatch, navigate, form, id, auth) => {
  dispatch(notificationActions.setNotify(true));

  const body = {
    piAmount: form.get("amount"),
    adsId: id,
  };
  try {
    const data = await apiRequest("orders", body, "POST", auth);
    if (data.message?.includes("generating a wallet")) {
      dispatch(notificationActions.setMessage(data.message));
    } else {
      dispatch(notificationActions.setNotify(false));
      navigate("/account/orders/id/" + data.id);
    }
  } catch (error) {
    console.log(error);
    if (error?.info?.error?.status === 422) {
      dispatch(notificationActions.setMessage(error?.info?.error?.message));
    } else {
      dispatch(notificationActions.setMessage("Something went wrong"));
    }
  }
};

export const getOrders = async (dispatch, auth) => {
  try {
    dispatch(notificationActions.setNotify(true));

    const data = await apiRequest("orders", undefined, undefined, auth);
    dispatch(notificationActions.setNotify(false));

    return data.orders;
  } catch (error) {
    dispatch(notificationActions.setNotify(false));

    if (error?.info?.error?.status === 422) {
      dispatch(notificationActions.setMessage(error?.info?.error?.message));
    } else {
      dispatch(notificationActions.setMessage("Something went wrong"));
    }
  }
};

export const getOrderById = async (dispatch, navigate, id, auth) => {
  dispatch(notificationActions.setNotify(true));

  try {
    const data = await apiRequest(
      "orders/id/" + id,
      undefined,
      undefined,
      auth
    );
    dispatch(notificationActions.setNotify(false));

    return data;
  } catch (error) {
    if (error?.info?.error?.status === 422) {
      dispatch(notificationActions.setMessage(error?.info?.error?.message));
    } else {
      dispatch(notificationActions.setMessage("Something went wrong"));
    }
    navigate("/account/orders/history");
  }
};

export const getMerchOrders = async (dispatch, auth) => {
  dispatch(notificationActions.setNotify(true));

  try {
    const data = await apiRequest(
      "orders/merchant",
      undefined,
      undefined,
      auth
    );
    dispatch(notificationActions.setNotify(false));

    return data.orders;
  } catch (error) {
    dispatch(notificationActions.setNotify(false));

    if (error?.info?.error?.status === 422) {
      console.log(error?.info?.error?.message);
    } else {
      console.log("Something went wrong");
    }
  }
};

export const getMerchOrderById = async (dispatch, navigate, id, auth) => {
  try {
    const data = await apiRequest(
      "orders/merchant/id/" + id,
      undefined,
      undefined,
      auth
    );
    return data;
  } catch (error) {
    if (error?.info?.error?.status === 422) {
      dispatch(notificationActions.setMessage(error?.info?.error?.message));
    } else {
      dispatch(notificationActions.setMessage("Something went wrong"));
    }
    navigate("/account/ads");
  }
};

export const cancelOrder = async (dispatch, refresh, id, navigate, auth) => {
  dispatch(notificationActions.setNotify(true));
  try {
    await apiRequest("orders/cancel-order/" + id, undefined, undefined, auth);
    dispatch(notificationActions.setNotify(false));
    dispatch(notificationActions.setRefresh(!refresh));

    navigate("/account/orders/history");
  } catch (error) {
    if (error?.info?.error?.status === 422) {
      dispatch(notificationActions.setMessage(error?.info?.error?.message));
    } else {
      dispatch(notificationActions.setMessage("Something went wrong"));
    }
  }
};

export const sentPi = async (dispatch, refresh, id, auth) => {
  dispatch(notificationActions.setNotify(true));
  try {
    await apiRequest("orders/auto-verify/" + id, undefined, undefined, auth);
    dispatch(notificationActions.setNotify(false));
    dispatch(notificationActions.setRefresh(!refresh));
  } catch (error) {
    if (error?.info?.error?.status === 422) {
      dispatch(notificationActions.setMessage(error?.info?.error?.message));
    } else {
      dispatch(notificationActions.setMessage("Something went wrong"));
    }
  }
};

export const piReceived = async (dispatch, refresh, id, auth) => {
  dispatch(notificationActions.setNotify(true));
  try {
    await apiRequest("orders/complete-order/" + id, undefined, "POST", auth);
    dispatch(notificationActions.setNotify(false));
    dispatch(notificationActions.setRefresh(!refresh));
  } catch (error) {
    if (error?.info?.error?.status === 422) {
      dispatch(notificationActions.setMessage(error?.info?.error?.message));
    } else {
      dispatch(notificationActions.setMessage("Something went wrong"));
    }
  }
};

export const genAppeal = async (dispatch, refresh, id, auth) => {
  dispatch(notificationActions.setNotify(true));
  try {
    await apiRequest("orders/appeal/" + id, undefined, "POST", auth);
    dispatch(notificationActions.setNotify(false));
    dispatch(notificationActions.setRefresh(!refresh));
  } catch (error) {
    if (error?.info?.error?.status === 422) {
      dispatch(notificationActions.setMessage(error?.info?.error?.message));
    } else {
      dispatch(notificationActions.setMessage("Something went wrong"));
    }
  }
};

export const rateOrder = async (dispatch, refresh, id, rating, auth) => {
  dispatch(notificationActions.setNotify(true));
  const body = {
    rating,
  };
  try {
    await apiRequest("orders/rate/" + id, body, "POST", auth);
    dispatch(notificationActions.setNotify(false));
    dispatch(notificationActions.setRefresh(!refresh));
  } catch (error) {
    if (error?.info?.error?.status === 422) {
      dispatch(notificationActions.setMessage(error?.info?.error?.message));
    } else {
      dispatch(notificationActions.setMessage("Something went wrong"));
    }
  }
};
