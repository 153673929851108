import React from "react";

import styles from "./emptyList.module.css";
const EmptyList = ({ text }) => {
  return (
    <div className={styles.container}>
      <h3> {text}</h3>
    </div>
  );
};

export default EmptyList;
