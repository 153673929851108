import React from "react";

import styles from "./sparkline.module.css";
import { Sparklines, SparklinesCurve } from "react-sparklines";
import { useSelector } from "react-redux";
import numberWithCommas from "../../helpers/commaSeperator";

const Sparkline = () => {
  const { price } = useSelector((state) => state.info);
  const change = (price.last_price - price.yesterdaysPrice) * 100;

  return (
    <div className={styles.spark}>
      <div className={styles.performance}>
        <p>Performance</p>
        <span>(24h)</span>
      </div>
      <div className={styles.price}>
        <h3>{price.last_price} USDT</h3>{" "}
        <span className={change >= 0 ? styles.green : styles.red}>
          {change > 0 ? "+" : ""}
          {numberWithCommas(change.toFixed(2))}%
        </span>
      </div>
      <Sparklines
        data={price.lastNine}
        min={0}
        preserveAspectRatio="xMinYMin "
        height={100}
      >
        <SparklinesCurve color="#D89BF3" />
      </Sparklines>
    </div>
  );
};

export default Sparkline;
