import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import CopyToClipboard from "react-copy-to-clipboard";

import styles from "./deposit.module.css";
import { controlActions } from "../../../../store/controls/controls";
import { genWall } from "../../accounthelper";
import { useNavigate } from "react-router";
import useOutsideAlerter from "../../../../hooks/useOutsideClick";
import { QRCodeSVG } from "qrcode.react";
const Deposit = () => {
  const { deposit } = useSelector((state) => state.control);
  const dispatch = useDispatch();
  const { balance } = useSelector((state) => state.auth);
  const authState = useSelector((state) => state.auth.loggedIn);
  const navigate = useNavigate();

  const handleWalletGen = async () => {
    await genWall(dispatch, navigate, authState);
  };

  const ref = useRef(null);

  useOutsideAlerter(ref, ref, () => dispatch(controlActions.setDeposit(false)));

  return (
    <div
      ref={ref}
      className={`${styles.deposit} ${deposit ? styles.open : undefined}`}
    >
      <div className={styles.container}>
        <img
          onClick={() => dispatch(controlActions.setDeposit(false))}
          src={require("../../../../assets/not-check.png")}
          alt="cancel"
        />
        <h4>Deposit Bep20 USDT</h4>
        {!balance.deposit_wallet ? (
          <>
            <button onClick={handleWalletGen}>
              <img
                src={require("../../../../assets/deposit.png")}
                alt="deposit USDT"
              />
              <p>Generate Wallet</p>
            </button>
            <div className={styles.wallet}>
              <span className={styles.quickNote}>
                $1.50 would be charged from your first deposit for wallet
                activation
              </span>
            </div>
          </>
        ) : (
          <div className={styles.wallet}>
            <span>Wallet Address</span>
            <div>
              <p>{balance.deposit_wallet}</p>
              <CopyToClipboard
                text={balance.deposit_wallet}
                onCopy={() => alert("copied")}
              >
                <img src={require("../../../../assets/copy.png")} alt="copy" />
              </CopyToClipboard>
            </div>
            <QRCodeSVG value={balance.deposit_wallet} />
          </div>
        )}

        <div className={styles.note}>
          <span>Important</span>
          <ul>
            <li>
              Minimum deposit is <strong>10 USDT</strong> any amount lesser
              would not be processed and cannot be recovered
            </li>
            <li>
              Send only <strong>USDT</strong> BEP20.
            </li>
            <li>
              Deposit would be process after <strong>15</strong> confirmations
              on the blockchain.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Deposit;
