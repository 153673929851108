import React from "react";
import { SectionWidth } from "../sectionWidth/sectionWidth";
import styles from "./faq.module.css";
import Card from "./card/card";

const Faq = () => {
  const faqs = [
    {
      question: "What is Pi_GOX?",
      answer: (
        <>
          <span>
            Pi_GOX is a P2P (Peer-to-Peer) also known as C2C
            (Customer-to-Customer in some regions) trading platform for the
            trading of Pi Network Currency. In P2P trading, the users directly
            buy or sell digital assets from each other on a P2P platform,
            exchange, or marketplace.
          </span>
          <br />
          <br />
          <span>
            A P2P platform serves as the trade facilitator by providing a
            platform for buyers and sellers to broadcast their offers. At the
            same time, it can ensure the safety and timely delivery of digital
            assets during trade execution.
          </span>
        </>
      ),
    },
    {
      question: "Are trade offers provided by Pi_GOX?",
      answer:
        "No, the offers you see on the P2P market are not offered by Pi_GOX. Pi_GOX serves as a platform to facilitate P2P trading, but the offers are provided by users on an individual basis.",
    },
    {
      question: "As a P2P trader, How am I protected?",
      answer: (
        <>
          <span>
            All online trades are protected by escrow. When an ad is posted, the
            amount of crypto for the ad is automatically reserved from the
            seller's Wallet. This means that if the seller does not release your
            crypto, the Customer Support agents can release the crypto to you
            from the reserved funds.
          </span>
          <br />
          <br />
          <span>
            If you're buying, never release the assets before confirming that
            you have received Pi in your wallet from the seller.
          </span>
        </>
      ),
    },
    {
      question: "How to Appeal?",
      answer: (
        <>
          <span>
            The Pi_GOX platform is built and designed to give users seamless
            transaction speed and less need to appeal.
          </span>
          <br />
          <span>
            In cases where users might have to appeal, you will find the appeal
            button just below your active order all you need to do is click on
            appeal and the cs will step in to resolve and refund where
            necessary.
          </span>
        </>
      ),
    },
    {
      question: "Is Pi_GOX safe to use?",
      answer:
        "Pi_GOX employs advanced security measures to ensure the safety of users' funds and data. Users' private keys are stored locally on their devices, and transactions are processed using a secure and decentralized system.",
    },
    {
      question: "I have more questions",
      answer:
        "Visit the website footer to access out docs that has a larger collection of FAQs. If you still have pending qquestions after this, kindly contact us via live support or email",
    },
  ];
  return (
    <div className={styles.container}>
      <SectionWidth className={styles.display}>
        <div className={styles.text}>
          <h2>FAQs</h2>
          <p>
            Buy and Sell your Pi tokens on the lagest P2P Marketplace in the
            world
          </p>
        </div>
        <div className={styles.cardcontainer}>
          {faqs.map((faq, index) => {
            return <Card key={index} data={faq} />;
          })}
        </div>
      </SectionWidth>
    </div>
  );
};

export default Faq;
