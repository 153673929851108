import React, { useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";

import styles from "./profile.module.css";
import { useDispatch, useSelector } from "react-redux";
import { deleteUser, updateUser } from "../settingshelper";
import { regSw, subscribe } from "../../../../helpers/utils";
import { confirmAlert } from "react-confirm-alert";
import { useNavigate } from "react-router";
const Profile = () => {
  const dispatch = useDispatch();
  const authState = useSelector((state) => state.auth.loggedIn);
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.auth);
  const [name, setName] = useState(user.name);
  const [nickName, setNickName] = useState(user.username);
  const [wallet, setWallet] = useState(user.wallet);

  const handleUpdateProfile = (e) => {
    e.preventDefault();
    const form = new FormData(e.target);

    updateUser(dispatch, form, user, authState);
  };

  const registerAndSubscribe = async () => {
    try {
      const serviceWorkerReg = await regSw();
      await subscribe(serviceWorkerReg, dispatch, authState, true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteUser = async () => {
    confirmAlert({
      title: "Confirm Account Deleteion",
      message: "Are you sure you want to delete your account permanently?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => await deleteUser(dispatch, navigate, authState),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };
  return (
    <div className={styles.container}>
      <div>
        <p className={styles.reflink}>
          <strong>Referral Link:</strong>
        </p>
        <div className={styles.refCOntainer}>
          <p className={styles.reflink}>
            https://{window.location.hostname}/auth/signup/{user.code}
          </p>
          <CopyToClipboard
            text={`https://${window.location.hostname}/auth/signup/${user.code}`}
            onCopy={() => alert("copied")}
          >
            <img src={require("../../../../assets/copy.png")} alt="copy" />
          </CopyToClipboard>
        </div>

        <form onSubmit={handleUpdateProfile}>
          <label>
            Name
            <input
              type="text"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </label>
          <label>
            Nickname
            <input
              type="text"
              name="username"
              value={nickName}
              onChange={(e) => setNickName(e.target.value)}
              required
            />
          </label>
          <label>
            Pi Wallet address
            <input
              type="text"
              name="wallet"
              value={wallet}
              onChange={(e) => setWallet(e.target.value)}
              required
            />
          </label>
          <span>NOTE: Pi Wallet must be a Verified Mainet Wallet Address</span>
          <input type="submit" value="Update" />
        </form>
        <div className={styles.notification}>
          <button onClick={registerAndSubscribe}>
            Re-activate Notification
          </button>
          <p>
            If for some reasons you stopped receiving order notifications, you
            can reactivate here
          </p>
        </div>

        <button className={styles.accountdeletion} onClick={handleDeleteUser}>
          Delete My Account
        </button>
      </div>

      <div>
        <p>Enable 2FA</p>
        <span>(coming soon)</span>
      </div>
    </div>
  );
};

export default Profile;
