import React from "react";

import styles from "./priceCard.module.css";
import { useNavigate } from "react-router";
import Sparkline from "../../../sparkline/sparkline";

const PriceCard = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <Sparkline />
      <button onClick={() => navigate("/account/orders")}>
        <img src={require("../../../../assets/trade.png")} alt="trade" />
        <p>Trade</p>
      </button>
    </div>
  );
};

export default PriceCard;
