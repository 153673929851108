import React, { useState } from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router";
import Sparkline from "../../../sparkline/sparkline";

import styles from "./createAd.module.css";
import { createAd } from "../adshelper";
import { useDispatch, useSelector } from "react-redux";
import numberWithCommas from "../../../../helpers/commaSeperator";

const CreateAd = () => {
  const params = useParams();
  const type = params.type;

  const [amount, setamount] = useState(10);
  const [min, setMin] = useState(1);
  const [price, setPrice] = useState(1);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authState = useSelector((state) => state.auth.loggedIn);
  const { balance } = useSelector((state) => state.auth);

  const handleCreateAd = (e) => {
    e.preventDefault();
    const form = new FormData(e.target);

    createAd(dispatch, form, navigate, type, authState);
  };
  return (
    <div className={styles.container}>
      <div>
        <div onClick={() => navigate("/account/ads")}>
          <img src={require("../../../../assets/arrL.png")} alt="arr" />
          <p>Back</p>
        </div>
        <h3>Create {type[0].toUpperCase() + type.slice(1, type.length)} Ad</h3>
      </div>
      <div>
        <div className={styles.info}>
          <Sparkline />
        </div>
        <div className={styles.form}>
          <div>
            <span>Available(Min: 10 {type === "buy" ? "USDT" : "Pi"})</span>
            <p>
              {type === "buy" ? "USDT: " : "Pi"}
              {type === "buy" && numberWithCommas(balance?.available)}
            </p>
          </div>
          <form onSubmit={handleCreateAd}>
            <input
              className={styles.input}
              onChange={(e) => setamount(e.target.value)}
              type="tel"
              name="available"
              value={amount}
              required
            />

            <label>Min {type} (Min: 1)</label>
            <input
              className={styles.input}
              onChange={(e) => setMin(e.target.value)}
              type="tel"
              name="min"
              value={min}
              required
            />

            <label>Price</label>

            <input
              className={styles.input}
              onChange={(e) => setPrice(e.target.value)}
              type="tel"
              name="price"
              value={price}
              required
            />

            <input
              className={styles.input}
              type="hidden"
              name="type"
              value="price"
              required
            />

            <input
              className={type === "buy" ? styles.buy : styles.sell}
              type="submit"
              value="Create"
            />
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateAd;
