import { apiRequest } from "./connections";
import { notificationActions } from "../store/notification/notification";

export const getFromLocalStorage = (key) => {
  const expiry = JSON.parse(localStorage.getItem("expiry"));
  if (new Date().getTime() > expiry) {
    localStorage.removeItem("connection");
    localStorage.removeItem("authState");
    return false;
  } else {
    return JSON.parse(localStorage.getItem(key));
  }
};

export const setLocalStorage = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

async function regSw() {
  if ("serviceWorker" in navigator) {
    let url = process.env.PUBLIC_URL + "/sw.js";
    const reg = await navigator.serviceWorker.register(url, { scope: "/" });
    return reg;
  }
  throw Error("serviceworker not supported");
}

async function subscribe(serviceWorkerReg, dispatch, auth, update = false) {
  dispatch(notificationActions.setNotify(true));
  let subscription = await serviceWorkerReg.pushManager.getSubscription();
  if (subscription === null) {
    subscription = await serviceWorkerReg.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey:
        "BLbIk9dd4XxFz2dpqR9qZ0388nrkzgmY_nEAaxKgOiInHgWxQWtDohW4rXvnaM3vacxGji5aexxuLuZQE_lsLrM",
    });
  }
  try {
    if (update) {
      await apiRequest("user/subscribe", subscription, "PUT", auth);
    } else await apiRequest("user/subscribe", subscription, "POST", auth);

    dispatch(
      notificationActions.setMessage("Notification subscription completed")
    );
  } catch (error) {
    if (error?.info?.error?.status === 422) {
      dispatch(notificationActions.setMessage(error?.info?.error?.message));
    } else {
      dispatch(notificationActions.setMessage("Something went wrong"));
    }
  }
}
export { regSw, subscribe };
