import React, { useEffect, useState } from "react";

import styles from "./trade.module.css";
import { useNavigate, useParams } from "react-router";
import numberWithCommas from "../../../../helpers/commaSeperator";
import Sparkline from "../../../sparkline/sparkline";
import { useDispatch, useSelector } from "react-redux";
import { getAdById } from "../../ads/adshelper";
import { fee } from "../../../../helpers/feeCalc";
import { createOrder } from "../ordershelper";

const Trade = () => {
  function numberToTwoDecimals(num) {
    if (num) {
      var with2Decimals = num.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
      return Number(with2Decimals);
    } else {
      return 0;
    }
  }
  const params = useParams();
  const id = params.id;

  const [amount, setamount] = useState(10);
  const [data, setData] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authState = useSelector((state) => state.auth.loggedIn);

  useEffect(() => {
    let abortController;
    (async function () {
      abortController = new AbortController();
      const res = await getAdById(dispatch, navigate, id, authState);
      const data = res.ad[0];
      setData(data);
    })();
    return () => abortController.abort();
  }, [authState, dispatch, id, navigate]);

  const handleOrder = async (e) => {
    e.preventDefault();
    const form = new FormData(e.target);
    createOrder(dispatch, navigate, form, data.ad_id, authState);
  };

  return (
    <div className={styles.container}>
      <div onClick={() => navigate("/account/orders")}>
        <img src={require("../../../../assets/arrL.png")} alt="arr" />
        <p>Back</p>
      </div>
      {Object.keys(data).length > 0 && (
        <div>
          <div className={styles.info}>
            <div>
              <div>
                <span>Name</span>
                <p>{data.username}</p>
              </div>
              <div>
                <span>Price</span>
                <p>{data.price} USDT</p>
              </div>
            </div>
            <div>
              <div>
                <span>Min.</span>
                <p>{data.min} Pi</p>
              </div>
              <div>
                <span>Available</span>
                <p>
                  {numberWithCommas(data.available)}{" "}
                  {data.type.toLowerCase() === "sell" ? "Pi" : "USDT"}
                </p>
              </div>
            </div>
            <Sparkline />
          </div>
          <div className={styles.form}>
            <div>
              <span>Amount</span>
              <p>Pi</p>
            </div>
            <form onSubmit={handleOrder}>
              <input
                onChange={(e) => setamount(e.target.value)}
                type="tel"
                name="amount"
                value={amount}
                required
              />
              <input
                className={
                  data.type.toLowerCase() === "sell" ? styles.buy : styles.sell
                }
                type="submit"
                value={data.type.toLowerCase() === "sell" ? "Buy" : "Sell"}
              />
            </form>
            <div>
              <span>Value</span>
              <p>{numberToTwoDecimals(amount * data.price)} USDT</p>
            </div>
            <div>
              <span>Fees</span>
              <p>{numberToTwoDecimals(fee(amount * data.price))} USDT</p>
            </div>
            <div>
              <span>
                {data.type.toLowerCase() === "sell"
                  ? "Total to pay"
                  : "You will get"}
              </span>
              <p>
                {data.type.toLowerCase() === "sell"
                  ? numberToTwoDecimals(
                      amount * data.price + fee(amount * data.price)
                    )
                  : numberToTwoDecimals(
                      amount * data.price - fee(amount * data.price)
                    )}{" "}
                USDT
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Trade;
