import React from "react";
import { Navigate, Route, Routes } from "react-router";

import styles from "./auth.module.css";
import Login from "../../components/auth/login/login";
import Signup from "../../components/auth/signup/signup";
import Authsidebar from "../../components/auth/authsidebar/authsidebar";
import Header from "../../components/auth/header/header";
import { SectionWidth } from "../../components/sectionWidth/sectionWidth";
import Verify from "../../components/auth/verify/verify";
import Reset from "../../components/auth/reset/reset";
import SetPassword from "../../components/auth/setPassword/setPassword";

const Auth = () => {
  return (
    <div className={styles.container}>
      <SectionWidth>
        <Header />
        <Routes>
          {/* <Route path="/" exact element={<Hompage />} />
    <Route path="/verify/:id" exact element={<Verify />} /> */}

          <Route path="login" exact element={<Login />} />
          <Route path="signup" exact element={<Signup />} />
          <Route path="signup/:refid" element={<Signup />} />

          <Route path="reset" exact element={<Reset />} />
          <Route path="verify/:token" element={<Verify />} />
          <Route path="set-password" exact element={<SetPassword />} />

          <Route path={"*"} element={<Navigate replace to="/auth/login" />} />
        </Routes>
      </SectionWidth>

      <Authsidebar />
    </div>
  );
};

export default Auth;
