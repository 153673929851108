import React from "react";

import styles from "./header.module.css";
import { useNavigate } from "react-router";
const Header = () => {
  const navigate = useNavigate();
  return (
    <header className={styles.header}>
      <img
        onClick={() => navigate("/")}
        src={require("../../../assets/logo.png")}
        alt="logo"
      />
      <div onClick={() => navigate("/")}>
        <h1>Pi_</h1>
        <h1>GOX</h1>
      </div>
    </header>
  );
};

export default Header;
