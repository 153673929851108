import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import styles from "./transTable.module.css";
import TransCard from "./transCard/transCard";
import AppPagination from "../../../pagination/pagination";
import { getTansactions } from "../../accounthelper";
import EmptyList from "../../../emptyList/emptyList";

const TransTable = () => {
  const [data, setData] = useState([]);
  const authState = useSelector((state) => state.auth.loggedIn);

  const { notify } = useSelector((state) => state.notification);
  useEffect(() => {
    let abortController;
    (async function () {
      abortController = new AbortController();
      const res = await getTansactions(authState);
      setData(res.reverse());
    })();
    return () => abortController.abort();
  }, [notify, authState]);

  const [transData, setTransData] = useState([]);
  return (
    <>
      {data.length === 0 ? (
        <EmptyList text="You have no Transactions" />
      ) : (
        <>
          <div className={styles.container}>
            {transData.map((item, index) => {
              return <TransCard key={index} data={item} />;
            })}
          </div>

          <div className={styles.paginationContainer}>
            <AppPagination
              callback={setTransData}
              rawData={data}
              pageSize={4}
            />
          </div>
        </>
      )}
    </>
  );
};

export default TransTable;
