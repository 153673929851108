import { apiRequest } from "../../../helpers/connections";
import { notificationActions } from "../../../store/notification/notification";
import { authSliceActions } from "../../../store/auth/auth";

export const updateUser = async (dispatch, form, user, auth) => {
  dispatch(notificationActions.setNotify(true));
  const body = {
    name: form.get("name"),
    wallet: form.get("wallet"),
    username: form.get("username"),
  };
  try {
    const data = await apiRequest("user/update-user", body, "PUT", auth);
    //set value to local and state
    const updated = { ...user };
    updated["name"] = body.name;
    updated["wallet"] = body.wallet;
    updated["username"] = body.username;
    dispatch(authSliceActions.setUser(updated));
    dispatch(notificationActions.setMessage(data.message));
  } catch (error) {
    if (error?.info?.error?.message.includes("Duplicate")) {
      dispatch(
        notificationActions.setMessage(
          "Wallet address has already been linked to another user on Pi_GOX"
        )
      );
    } else if (error?.info?.error?.status === 422) {
      dispatch(notificationActions.setMessage(error?.info?.error?.message));
    } else {
      dispatch(notificationActions.setMessage("Something went wrong"));
    }
  }
};

export const deleteUser = async (dispatch, navigate, auth) => {
  dispatch(notificationActions.setNotify(true));

  try {
    const data = await apiRequest("user", undefined, "DELETE", auth);
    //set value to local and state

    dispatch(notificationActions.setMessage(data.message));
    navigate("/auth/login");
  } catch (error) {
    if (error?.info?.error?.status === 422) {
      dispatch(notificationActions.setMessage(error?.info?.error?.message));
    } else {
      dispatch(notificationActions.setMessage("Something went wrong"));
    }
  }
};

export const submitID = async (dispatch, form, auth) => {
  dispatch(notificationActions.setNotify(true));

  try {
    const data = await apiRequest("user/image-upload", form, "POST", auth);
    //set value to local and state
    dispatch(notificationActions.setMessage(data.message));
  } catch (error) {
    if (error?.info?.errors[0]?.detail.includes("Not an image")) {
      dispatch(notificationActions.setMessage(error?.info?.errors[0]?.detail));
    } else {
      dispatch(notificationActions.setMessage("Upload Failed, try again"));
    }
  }
};

export const getVerification = async (auth) => {
  try {
    const data = await apiRequest(
      "user/verification",
      undefined,
      undefined,
      auth
    );
    return data.data;
  } catch (error) {
    console.log(error);
  }
};
