import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./auth/auth";
import infoSlice from "./info/info";
import notificationSlice from "./notification/notification";
import controlSlice from "./controls/controls";

const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    info: infoSlice.reducer,
    notification: notificationSlice.reducer,
    control: controlSlice.reducer,
  },
});

export default store;
