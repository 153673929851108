import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  price: {},
};

const infoSlice = createSlice({
  name: "info",
  initialState,
  reducers: {
    setPrice(state, action) {
      state.price = action.payload;
    },
  },
});

export const infoActions = infoSlice.actions;

export default infoSlice;
