import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { controlActions } from "../store/controls/controls";
import { getBalance, pidata } from "../components/account/accounthelper";

export const ScrollToTop = () => {
  const dispatch = useDispatch();
  const authState = useSelector((state) => state.auth.loggedIn);

  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
    //close sidebar
    authState.state && getBalance(dispatch, authState);
    authState.state && pidata(dispatch);
    dispatch(controlActions.setSidebar(false));
  }, [pathname, dispatch, authState]);
  return null;
};
