import React, { useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router";
import { getOrderById } from "../ordershelper";
import { useDispatch, useSelector } from "react-redux";
import Buy from "./buy/buy";
import Sell from "./sell/sell";

const SingleOrder = () => {
  const { user } = useSelector((state) => state.auth);
  const [data, setdata] = useState({});
  // const data = {
  //   id: "45sfdfd",
  //   type: "buy",
  //   pi_amount: 22.9,
  //   usdt_amount: 22.9,
  //   merchant_wallet: "Merch09d8ef3a3e921f61b4a7dfbc4c17b405b29c80",
  //   user_wallet: "User09d8ef3a3e921f61b4a7dfbc4c17b405b29c80",
  //   status: "pending",
  //   auto: false,
  //   stage: "first",
  //   appeal: null,
  //   user_id: 5,
  //   merchant_id: 1,
  //   last_updated: "2023-02-27 05:08:37",
  //   date: "2023-02-27 05:08:37",
  //   merchant_rating: null,
  //   user_rating: null,
  //   merchant_fee: 0.15,
  //   user_fee: 0.3,
  // };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authState = useSelector((state) => state.auth.loggedIn);
  const params = useParams();
  const { refresh } = useSelector((state) => state.notification);

  const id = params.id;

  useEffect(() => {
    let abortController;
    (async function () {
      abortController = new AbortController();
      const res = await getOrderById(dispatch, navigate, id, authState);
      const data = res.order[0];
      setdata(data);
    })();
    return () => abortController.abort();
  }, [authState, dispatch, id, navigate, refresh]);

  const UserOrder = () => {
    return (
      <>{data.type === "sell" ? <Buy data={data} /> : <Sell data={data} />}</>
    );
  };

  const MerchantOrder = () => {
    return (
      <>
        {data.type === "sell" ? (
          <Sell data={data} merch={true} />
        ) : (
          <Buy data={data} merch={true} />
        )}
      </>
    );
  };

  return (
    <>
      {Object.keys(data).length > 0 && user.id === data.user_id ? (
        <UserOrder />
      ) : (
        Object.keys(data).length > 0 && <MerchantOrder />
      )}
    </>
  );
};

export default SingleOrder;
