import React from "react";

import styles from "./reset.module.css";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { getToken } from "../authhelper";
const Reset = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleReset = (e) => {
    e.preventDefault();
    const form = new FormData(e.target);

    getToken(dispatch, form, navigate);
  };
  return (
    <div className={styles.container}>
      <h3>Reset Password</h3>
      <form onSubmit={handleReset}>
        <input
          type="email"
          placeholder="Email Address for One-Time password"
          name="email"
          required
        />

        <input type="submit" value="Send Token" />
      </form>
    </div>
  );
};

export default Reset;
