import { useState, useEffect, useCallback, useRef } from "react";
import styles from "./verify.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { apiRequest } from "../.././../helpers/connections";
import { notificationActions } from "../../../store/notification/notification";
import ReCAPTCHA from "react-google-recaptcha";
export const Verify = () => {
  const [verification, setVerification] = useState(false);
  const [resend, setResend] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const params = useParams();
  const ref = useRef();

  const handleResend = async (e) => {
    e.preventDefault();
    const form = new FormData(e.target);
    const token = ref.current.getValue();
    if (token) {
      form.append("response", token);
      ref.current.reset();
      try {
        dispatch(notificationActions.setNotify(true));

        await apiRequest(
          "auth/resend",
          { email: form.get("email"), response: form.get("response") },
          "POST"
        );
        dispatch(
          notificationActions.setMessage(
            "A new verification link has been sent to you"
          )
        );
        navigate("/auth/login");
      } catch (error) {
        if (error?.info?.error?.status === 422) {
          dispatch(notificationActions.setMessage(error?.info?.error?.message));
        } else {
          dispatch(notificationActions.setMessage("Something went wrong"));
        }
        setResend(true);
      }
    }
  };

  const handleVerification = useCallback(async () => {
    try {
      dispatch(notificationActions.setNotify(true));

      await apiRequest("auth/verify", { token: params.token }, "POST");
      dispatch(
        notificationActions.setMessage(
          "Verification Successful, Your referal code has been sent to your email"
        )
      );
      setVerification(true);
    } catch (error) {
      if (error?.info?.error?.status === 422) {
        dispatch(notificationActions.setMessage(error?.info?.error?.message));
      } else {
        dispatch(notificationActions.setMessage("Something went wrong"));
      }
      setResend(true);
    }
  }, [dispatch, params.token]);

  useEffect(() => {
    if (!verification) {
      handleVerification();
    }
    if (verification) {
      const timeout = setTimeout(() => {
        navigate("/auth/login");
      }, 1000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [navigate, verification, handleVerification]);
  return (
    <div className={styles.container}>
      {resend && (
        <form className={styles.form} onSubmit={handleResend}>
          <input
            name="email"
            type="email"
            placeholder="Email Address"
            required
          />
          <ReCAPTCHA
            ref={ref}
            sitekey="6LcWCeEkAAAAAMavUV2owwFsoumnLqS51JZgK2O-"
          />
          <button>Resend Verification</button>
        </form>
      )}
    </div>
  );
};

export default Verify;
