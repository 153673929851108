import React, { useEffect, useState } from "react";

import styles from "./history.module.css";
import Header from "./header/header";
import HistoryCard from "./historyCard/historyCard";
import AppPagination from "../../../pagination/pagination";
import { useDispatch, useSelector } from "react-redux";
import { getOrders } from "../ordershelper";
import EmptyList from "../../../emptyList/emptyList";

const History = () => {
  const [active, setactive] = useState("sell");
  const [transData, setTransData] = useState([]);
  const [filtered, setfiltered] = useState([]);
  const [category, setCategory] = useState("All");
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const authState = useSelector((state) => state.auth.loggedIn);

  useEffect(() => {
    let abortController;
    (async function () {
      abortController = new AbortController();
      const data = await getOrders(dispatch, authState);
      setData(data.reverse());
    })();
    return () => abortController.abort();
  }, [dispatch, authState]);

  useEffect(() => {
    if (data) {
      if (category === "All") {
        setfiltered(data.filter((item) => item.type.toLowerCase() === active));
      } else if (category === "Pending") {
        const list = data.filter((item) => item.type.toLowerCase() === active);
        setfiltered(
          list.filter((item) => item.status.toLowerCase() === "pending")
        );
      } else if (category === "Completed") {
        const list = data.filter((item) => item.type.toLowerCase() === active);
        setfiltered(
          list.filter((item) => item.status.toLowerCase() === "completed")
        );
      } else if (category === "Cancelled") {
        const list = data.filter((item) => item.type.toLowerCase() === active);
        setfiltered(
          list.filter((item) => item.status.toLowerCase() === "cancelled")
        );
      }
    }
  }, [active, data, category]);

  return (
    <>
      <Header
        active={active}
        setactive={setactive}
        category={category}
        setCategory={setCategory}
      />
      {data?.length > 0 ? (
        <>
          {filtered.length === 0 ? (
            <EmptyList text="You have no Orders in this Category" />
          ) : (
            <>
              <div className={styles.container}>
                {transData.map((item, index) => {
                  return <HistoryCard key={index} data={item} />;
                })}
              </div>

              <div className={styles.paginationContainer}>
                <AppPagination
                  callback={setTransData}
                  rawData={filtered}
                  pageSize={4}
                />
              </div>
            </>
          )}
        </>
      ) : (
        <EmptyList text="You have no Orders in this Category" />
      )}
    </>
  );
};

export default History;
