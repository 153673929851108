import { Navigate, Route, Routes } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import PrivateRoutes from "./helpers/privateRoute";
import Auth from "./pages/auth/auth";
import Account from "./pages/account/account";
import Home from "./pages/home/home";
import { Message } from "./components/message/message";
import { useEffect } from "react";
import { notificationActions } from "./store/notification/notification";

const App = () => {
  const { notify, message, loading } = useSelector(
    (state) => state.notification
  );

  const dispatch = useDispatch();

  //to prevent an eternal loading scenerio
  useEffect(() => {
    const timeout = setTimeout(() => {
      notify &&
        dispatch(
          notificationActions.setMessage("Connection Timeout, Try again")
        );
    }, 25000);
    return () => {
      clearTimeout(timeout);
    };
  }, [notify, dispatch]);
  return (
    <>
      {notify && <Message message={message} loading={loading} />}
      <Routes>
        <Route path="" exact element={<Home />} />

        <Route path="/auth/*" exact element={<Auth />} />
        <Route element={<PrivateRoutes />}>
          <Route path="/account/*" exact element={<Account />} />
        </Route>
        <Route path={"*"} element={<Navigate replace to="" />} />
      </Routes>
    </>
  );
};

export default App;
