import React from "react";

import styles from "./authsidebar.module.css";
import { SectionWidth } from "../../sectionWidth/sectionWidth";
const Authsidebar = () => {
  return (
    <div className={styles.container}>
      <SectionWidth>
        <img src={require("../../../assets/piicon.png")} alt="pi icon" />
        <img src={require("../../../assets/piicon.png")} alt="pi icon" />
        <img src={require("../../../assets/piicon.png")} alt="pi icon" />
        <p>
          A crypto exchange is a platform that allows individuals to buy, sell,
          and trade different types of cryptocurrencies.
        </p>
      </SectionWidth>
      <img src={require("../../../assets/dashboard.png")} alt="dashboard" />
    </div>
  );
};

export default Authsidebar;
