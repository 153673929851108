import { useRef, useState } from "react";

import styles from "./dropDown.module.css";
import useOutsideAlerter from "../../../../../hooks/useOutsideClick";

export const DropDown = ({ data, setCountry, country }) => {
  const ref = useRef(null);
  const controllerRef = useRef(null);

  const [open, setOpen] = useState(false);

  useOutsideAlerter(ref, controllerRef, () => setOpen(false));

  const handleItemSelection = (item) => {
    setCountry(item);
    setOpen(false);
  };

  return (
    <div className={styles.formContainer}>
      <div
        ref={controllerRef}
        onClick={() => setOpen((prevState) => !prevState)}
        className={styles.dropDownHeader}
      >
        <p>{country}</p>
        <i
          className={`fa-solid fa-angle-down ${open ? styles.rotate : ""}`}
        ></i>
      </div>
      <div
        ref={ref}
        className={`${styles.dropdown} ${open ? styles.show : ""}`}
      >
        {data?.map((item, index) => {
          return (
            <li
              onClick={() => handleItemSelection(item)}
              className={country === item ? styles.remove : ""}
              key={index}
            >
              {item}
            </li>
          );
        })}
      </div>
    </div>
  );
};
