import React from "react";

import styles from "./hero.module.css";
import { useNavigate } from "react-router";

const Hero = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.hero}>
      <div>
        <img src={require("../../../assets/piicon.png")} alt="pi icon" />
        <img src={require("../../../assets/piicon.png")} alt="pi icon" />
        <img src={require("../../../assets/piicon.png")} alt="pi icon" />
        <img src={require("../../../assets/dots.png")} alt="pi icon" />
        <img src={require("../../../assets/dots.png")} alt="pi icon" />
      </div>

      <h1>Buy, sell, and trade your Pi like a pro.</h1>
      <p>
        Welcome to PI_GOX a peer to peer crypto market place where you can buy
        and sell your PI tokens without hassle.
      </p>
      <button
        onClick={() => navigate("/account/dashboard")}
        className={styles.action}
      >
        <p>Get started</p>
        <img src={require("../../../assets/started.png")} alt="get started" />
      </button>

      <img src={require("../../../assets/dashboard.png")} alt="dashboard" />
    </div>
  );
};

export default Hero;
