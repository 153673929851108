import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Header from "./header/header";
import styles from "./ordersSection.module.css";
import OrdersCard from "./ordersCard/ordersCard";
import AppPagination from "../../../pagination/pagination";
import AdsCard from "./adsCard/adsCard";
import { getAllAds } from "../adshelper";
import EmptyList from "../../../emptyList/emptyList";
import { getMerchOrders } from "../../orders/ordershelper";

const OrdersSection = () => {
  const [active, setactive] = useState("active");
  const [pagOrders, setPagOrders] = useState([]);
  const [pagAds, setPagAds] = useState([]);
  const [orders, setOrders] = useState([]);
  const [rawAds, setRawAds] = useState([]);
  const [rawOrders, setRawOrders] = useState([]);
  const [ads, setAds] = useState([]);
  const dispatch = useDispatch();
  const authState = useSelector((state) => state.auth.loggedIn);

  useEffect(() => {
    let abortController;
    (async function () {
      abortController = new AbortController();
      const data = await getAllAds(dispatch, authState);
      setRawAds(data);
    })();
    return () => abortController.abort();
  }, [dispatch, authState]);

  useEffect(() => {
    let abortController;
    (async function () {
      abortController = new AbortController();
      const data = await getMerchOrders(dispatch, authState);
      setRawOrders(data);
    })();
    return () => abortController.abort();
  }, [dispatch, authState]);

  useEffect(() => {
    rawOrders &&
      rawOrders?.length > 0 &&
      setOrders(
        rawOrders.filter((item) => item.status.toLowerCase() === "pending")
      );
    rawAds?.length > 0 &&
      setAds(rawAds.filter((item) => item.type.toLowerCase() === active));
  }, [active, rawOrders, rawAds]);

  const activeState = (
    <>
      {orders.length === 0 ? (
        <EmptyList text="You have no Active Orders" />
      ) : (
        <>
          <div className={styles.container}>
            {pagOrders.map((item, index) => {
              return <OrdersCard key={index} data={item} />;
            })}
          </div>

          <div className={styles.paginationContainer}>
            <AppPagination
              callback={setPagOrders}
              rawData={orders}
              pageSize={4}
            />
          </div>
        </>
      )}
    </>
  );

  const adsState = (
    <>
      {ads.length === 0 ? (
        <EmptyList text="You have no Ads" />
      ) : (
        <>
          <div className={styles.container}>
            {pagAds.map((item, index) => {
              return <AdsCard key={index} data={item} />;
            })}
          </div>

          <div className={styles.paginationContainer}>
            <AppPagination callback={setPagAds} rawData={ads} pageSize={4} />
          </div>
        </>
      )}
    </>
  );
  return (
    <>
      <Header active={active} setactive={setactive} />
      {active === "active" ? activeState : adsState}
    </>
  );
};

export default OrdersSection;
