import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";

import styles from "./historyCard.module.css";
import { dateToText } from "../../../../../helpers/date";
import { useNavigate } from "react-router";

const HistoryCard = ({ data }) => {
  const date = dateToText(data.date);
  const navigate = useNavigate();
  return (
    <div
      className={styles.container}
      onClick={() => navigate("/account/orders/id/" + data.order_id)}
    >
      <div>
        <span>ID</span>
        <div className={styles.copy}>
          <p>{data.order_id}</p>
          <CopyToClipboard
            text={data.order_id}
            onCopy={() => alert("copied  ")}
          >
            <img src={require("../../../../../assets/copy.png")} alt="copy" />
          </CopyToClipboard>
        </div>
      </div>
      <div>
        <span>Price</span>
        <div>
          <p>{data.price} USDT</p>
        </div>
      </div>

      <div>
        <span>Amount (Pi)</span>
        <div>
          <p>{data.pi_amount}.0</p>
          <span>Pi</span>
        </div>
      </div>
      <div>
        <span>Amount (USDT)</span>
        <div>
          <p>{data.usdt_amount} </p>
          <span>USDT</span>
        </div>
      </div>
      <div>
        <span>Date</span>
        <p>{date}</p>
      </div>
      <div>
        <span>Status</span>
        <p>
          <img
            src={
              data.status === "completed"
                ? require("../../../../../assets/completed.png")
                : data.status === "pending"
                ? require("../../../../../assets/pendingG.png")
                : require("../../../../../assets/cancel.png")
            }
            alt="status"
          />
        </p>
      </div>
    </div>
  );
};

export default HistoryCard;
