import React from "react";
import { animateScroll } from "react-scroll";

import styles from "./footer.module.css";
import { Link, useNavigate } from "react-router-dom";
const Footer = () => {
  const navigate = useNavigate();
  const surfacePush = () => {
    animateScroll.scrollToTop();
  };
  return (
    <footer className={styles.footer}>
      <div className={styles.platform}>
        <div className={styles.header}>
          <img
            onClick={() => navigate("/")}
            src={require("../../assets/logo.png")}
            alt="logo"
          />
          <div onClick={() => navigate("/")}>
            <h1>Pi_</h1>
            <h1>GOX</h1>
          </div>
        </div>
        <p className={styles.copyright}>Copyright @ 2023</p>
        <div className={styles.socials}>
          <a href="https://twitter.com/Pi_GOX">
            <img src={require("../../assets/twitter.png")} alt="twitter" />
          </a>
          <a href="mailto:support@pigox.org">
            <img src={require("../../assets/mail.png")} alt="mail" />
          </a>
        </div>
      </div>
      <div className={styles.links}>
        <div>
          <Link to="">
            <strong>Links</strong>
          </Link>
          <Link to="/account">Dashboard</Link> <Link to="/account">Orders</Link>{" "}
          <Link to="/account">Ads</Link>{" "}
        </div>
        <div>
          <Link to="">
            <strong>Docs</strong>
          </Link>
          <a
            href="https://docs.pigox.org/tutorial"
            target="_blank"
            rel="noreferrer"
          >
            Tutorial
          </a>
          <a href="https://docs.pigox.org/faq" target="_blank" rel="noreferrer">
            FAQs
          </a>
        </div>
        <div>
          <Link to="">
            <strong>Legals</strong>
          </Link>
          <a href="https://docs.pigox.org/" target="_blank" rel="noreferrer">
            Terms Of Use
          </a>
          <a
            href="https://docs.pigox.org/privacy-policy"
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
          <a
            href="https://docs.pigox.org/user-guidelines"
            target="_blank"
            rel="noreferrer"
          >
            User Guidelines
          </a>
        </div>
        <div>
          <Link to="">
            <strong>Support</strong>
          </Link>
          <a href="mailto:hello@pigox.org" target="_blank" rel="noreferrer">
            hello@pigox.org
          </a>
          <a href="mailto:support@pigox.org" target="_blank" rel="noreferrer">
            support@pigox.org
          </a>
        </div>
      </div>
      <div onClick={surfacePush}>
        <p>Scroll to Top</p>
        <img src={require("../../assets/hdarr.png")} alt="to top" />
      </div>
    </footer>
  );
};

export default Footer;
