import React, { useEffect, useState } from "react";

import Header from "./header/header";
import styles from "./ordersSection.module.css";
import OrdersCard from "./ordersCard/ordersCard";
import AppPagination from "../../../pagination/pagination";
import { getAllAds } from "../ordershelper";
import { useDispatch, useSelector } from "react-redux";
import EmptyList from "../../../emptyList/emptyList";

const OrdersSection = () => {
  const [active, setactive] = useState("sell");
  const [transData, setTransData] = useState([]);
  const [filtered, setfiltered] = useState([]);
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const authState = useSelector((state) => state.auth.loggedIn);

  useEffect(() => {
    let abortController;
    (async function () {
      abortController = new AbortController();
      const data = await getAllAds(dispatch, authState);
      setData(data);
    })();
    return () => abortController.abort();
  }, [dispatch, authState]);

  useEffect(() => {
    //filter sell from high to low and buy from low to high
    if (data) {
      let sorted;
      const filtered = data.filter(
        (item) => item.type.toLowerCase() === active
      );
      if (active === "buy") {
        sorted = filtered.sort((a, b) => b.price - a.price);
      } else {
        sorted = filtered.sort((a, b) => a.price - b.price);
      }
      setfiltered(sorted);
    }
  }, [active, data]);

  return (
    <>
      <Header active={active} setactive={setactive} />

      {filtered.length === 0 ? (
        <EmptyList text="There Are No Live Ads" />
      ) : (
        <>
          <div className={styles.container}>
            {transData.map((item, index) => {
              return <OrdersCard key={index} data={item} />;
            })}
          </div>

          <div className={styles.paginationContainer}>
            <AppPagination
              callback={setTransData}
              rawData={filtered}
              pageSize={4}
            />
          </div>
        </>
      )}
    </>
  );
};

export default OrdersSection;
