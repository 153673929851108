import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sidebar: false,
  deposit: false,
  withdrawal: false,
  selectedOrder: {},
};

const controlSlice = createSlice({
  name: "control",
  initialState,
  reducers: {
    setSidebar(state, action) {
      state.sidebar = action.payload;
    },
    setSelectedOrder(state, action) {
      state.selectedOrder = action.payload;
    },
    setDeposit(state, action) {
      state.deposit = action.payload;
    },
    setWithdrawal(state, action) {
      state.withdrawal = action.payload;
    },
  },
});

export const controlActions = controlSlice.actions;

export default controlSlice;
