import { apiRequest } from "../../../helpers/connections";
import { notificationActions } from "../../../store/notification/notification";

export const createAd = async (dispatch, form, navigate, type, auth) => {
  dispatch(notificationActions.setNotify(true));
  const available = form.get("available");
  const min = form.get("min");
  if (available < 10 || min < 1) {
    dispatch(
      notificationActions.setMessage("Value(s) can not be less that minimum")
    );
  }

  const body = {
    available: form.get("available"),
    min: form.get("min"),
    price: form.get("price"),
    type,
  };
  try {
    const data = await apiRequest("ads", body, "POST", auth);

    if (data.message.includes("generating a wallet")) {
      dispatch(notificationActions.setMessage(data.message));
    } else {
      dispatch(notificationActions.setNotify(false));
      navigate("/account/ads");
    }
  } catch (error) {
    if (error?.info?.error?.status === 422) {
      dispatch(notificationActions.setMessage(error?.info?.error?.message));
    } else {
      dispatch(notificationActions.setMessage("Something went wrong"));
    }
  }
};

export const editAd = async (dispatch, form, navigate, ad, auth) => {
  dispatch(notificationActions.setNotify(true));
  const available = form.get("available");
  const min = form.get("min");
  if (available < 10 || min < 1 ) {
    dispatch(
      notificationActions.setMessage("Value(s) can not be less that minimum")
    );
  }

  const body = {
    available: form.get("available"),
    min: form.get("min"),
    price: form.get("price"),
    type: ad.type,
  };
  try {
    const data = await apiRequest("ads/" + ad.ad_id, body, "PUT", auth);
    dispatch(notificationActions.setMessage(data.message));
    navigate("/account/ads");
  } catch (error) {
    if (error?.info?.error?.status === 422) {
      dispatch(notificationActions.setMessage(error?.info?.error?.message));
    } else {
      dispatch(notificationActions.setMessage("Something went wrong"));
    }
  }
};

export const getAllAds = async (dispatch, auth) => {
  dispatch(notificationActions.setNotify(true));

  try {
    const data = await apiRequest("ads/merch", undefined, undefined, auth);
    dispatch(notificationActions.setNotify(false));

    return data.ads;
  } catch (error) {
    dispatch(notificationActions.setNotify(false));

    if (error?.info?.error?.status === 422) {
      dispatch(notificationActions.setMessage(error?.info?.error?.message));
    } else {
      dispatch(notificationActions.setMessage("Something went wrong"));
    }
  }
};

export const getAdById = async (dispatch, navigate, id, auth) => {
  dispatch(notificationActions.setNotify(true));

  try {
    const data = await apiRequest("ads/" + id, undefined, undefined, auth);
    dispatch(notificationActions.setNotify(false));
    return data;
  } catch (error) {
    if (error?.info?.error?.status === 422) {
      dispatch(notificationActions.setMessage(error?.info?.error?.message));
    } else {
      dispatch(notificationActions.setMessage("Something went wrong"));
    }
    navigate("/account/ads");
  }
};

export const deleteAd = async (dispatch, navigate, ad, auth) => {
  dispatch(notificationActions.setNotify(true));
  try {
    const data = await apiRequest("ads/" + ad.ad_id, undefined, "DELETE", auth);
    dispatch(notificationActions.setMessage(data.message));
    navigate("/account/ads");
  } catch (error) {
    if (error?.info?.error?.status === 422) {
      dispatch(notificationActions.setMessage(error?.info?.error?.message));
    } else {
      dispatch(notificationActions.setMessage("Something went wrong"));
    }
  }
};
