import { authSliceActions } from "../../store/auth/auth";
import { apiRequest } from "../../helpers/connections";
import { notificationActions } from "../../store/notification/notification";
import { setLocalStorage } from "../../helpers/utils";

export const signup = async (dispatch, form, navigate, params) => {
  dispatch(notificationActions.setNotify(true));

  if (form.get("wallet").length > 144) {
    dispatch(notificationActions.setMessage("Invalid Pi Address"));
    return window.location.reload();
  }
  const body = {
    email: form.get("email"),
    name: form.get("name"),
    wallet: form.get("wallet"),
    password: form.get("password"),
    response: form.get("response"),
  };
  if (params.refid) {
    body["refid"] = params.refid;
  }

  try {
    await apiRequest("auth/signup", body, "POST");
    dispatch(
      notificationActions.setMessage(
        "A verification email has been sent to your email"
      )
    );
    navigate("/auth/login");
  } catch (error) {
    if (error?.info?.error?.message?.includes("password")) {
      dispatch(
        notificationActions.setMessage(
          "Password must have atleast 6 characters, 1 uppercase, 1number and 1 character"
        )
      );
    } else if (error?.info?.error?.message?.includes("response")) {
      window.location.reload();
    } else if (error?.info?.error?.message?.includes("Duplicate")) {
      dispatch(
        notificationActions.setMessage(
          "A user with this email or wallet address already exists"
        )
      );
    } else if (error?.info?.error?.status === 422) {
      dispatch(notificationActions.setMessage(error?.info?.error?.message));
    } else {
      dispatch(notificationActions.setMessage("Something went wrong"));
    }
  }
};

export const login = async (dispatch, form, navigate) => {
  dispatch(notificationActions.setNotify(true));

  const body = {
    email: form.get("email"),
    password: form.get("password"),
    response: form.get("response"),
  };

  try {
    const data = await apiRequest("auth/login", body, "POST");
    const auth = {};
    auth.state = true;
    auth.token = data.token;
    dispatch(authSliceActions.setLoggedIn(auth));
    dispatch(authSliceActions.setUser(data.user));
    setLocalStorage("expiry", new Date().getTime() + 36000000);
    dispatch(notificationActions.setNotify(false));
    navigate("/account");
  } catch (error) {
    if (error?.info?.error?.message?.includes("password")) {
      dispatch(notificationActions.setMessage("Incorrect Login Credentials"));
    } else if (error?.info?.error?.message?.includes("length must be at")) {
      dispatch(
        notificationActions.setMessage(
          "Password must have atleast 6 characters, 1 uppercase, 1number and 1 character"
        )
      );
    } else if (error?.info?.error?.message?.includes("response")) {
      window.location.reload();
    } else if (error?.info?.error?.status === 422) {
      dispatch(notificationActions.setMessage(error?.info?.error?.message));
    } else {
      dispatch(notificationActions.setMessage("Something went wrong"));
    }
  }
};

export const logout = async (dispatch, navigate, auth) => {
  dispatch(notificationActions.setNotify(true));

  try {
    await apiRequest("auth/logout", undefined, undefined, auth);
    dispatch(notificationActions.setNotify(false));
    localStorage.removeItem("authState");
    navigate("/auth/login");
  } catch (error) {
    if (error?.info?.error?.status === 422) {
      dispatch(notificationActions.setMessage(error?.info?.error?.message));
    } else {
      dispatch(notificationActions.setMessage("Something went wrong"));
    }
  }
};

export const getToken = async (dispatch, form, navigate) => {
  dispatch(notificationActions.setNotify(true));
  const body = {
    email: form.get("email"),
  };
  try {
    await apiRequest("auth/recover", body, "POST");
    dispatch(
      notificationActions.setMessage(
        "A One-Time password has been sent to your email"
      )
    );
    navigate("/auth/set-password");
  } catch (error) {
    if (error?.info?.error?.status === 422) {
      dispatch(notificationActions.setMessage(error?.info?.error?.message));
    } else {
      dispatch(notificationActions.setMessage("Something went wrong"));
    }
  }
};

export const reset = async (dispatch, form, navigate) => {
  dispatch(notificationActions.setNotify(true));
  const body = {
    token: form.get("token"),
    password: form.get("password"),
  };

  try {
    await apiRequest("auth/reset", body, "POST");
    dispatch(notificationActions.setMessage("Password reset complete"));
    navigate("/auth/login");
  } catch (error) {
    if (error?.info?.error?.message?.includes("password")) {
      dispatch(
        notificationActions.setMessage(
          "Password must have atleast 6 characters, 1 uppercase, 1number and 1 character"
        )
      );
    } else if (error?.info?.error?.status === 422) {
      dispatch(notificationActions.setMessage(error?.info?.error?.message));
    } else {
      dispatch(notificationActions.setMessage("Something went wrong"));
    }
  }
};
