import React, { useState } from "react";

import styles from "./balanceCard.module.css";
import numberWithCommas from "../../../../helpers/commaSeperator";
import { controlActions } from "../../../../store/controls/controls";
import { useDispatch, useSelector } from "react-redux";

const BalanceCard = () => {
  const dispatch = useDispatch();
  const { balance } = useSelector((state) => state.auth);

  const [open, setopen] = useState(true);
  return (
    <div className={styles.container}>
      <p>USDT Balance</p>
      <div>
        {open ? (
          <span>{numberWithCommas(balance?.total)}</span>
        ) : (
          <span>********</span>
        )}
        <p>USDT</p>
        <img
          onClick={() => setopen((prevState) => !prevState)}
          src={
            open
              ? require("../../../../assets/priceoff.png")
              : require("../../../../assets/priceon.png")
          }
          alt="price"
        />
      </div>
      <div className={styles.otherBal}>
        <div>
          <p>
            Available:{" "}
            {open ? numberWithCommas(balance?.available) : "********"} USDT
          </p>
          <p>
            Frozen: {open ? numberWithCommas(balance?.frozen) : "********"} USDT
          </p>

          <p>
            Bonus: {open ? numberWithCommas(balance?.balance) : "********"} USDT
          </p>
        </div>
      </div>
      <div>
        <button onClick={() => dispatch(controlActions.setDeposit(true))}>
          <img
            src={require("../../../../assets/deposit.png")}
            alt="deposit USDT"
          />
          <p>Deposit</p>
        </button>
        <button onClick={() => dispatch(controlActions.setWithdrawal(true))}>
          <img src={require("../../../../assets/send.png")} alt="send USDT" />{" "}
          <p>Send</p>
        </button>
      </div>
    </div>
  );
};

export default BalanceCard;
