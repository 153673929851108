import React from "react";
import styles from "./tradeSection.module.css";
import Button from "../button/button";
import { SectionWidth } from "../sectionWidth/sectionWidth";

const TradeSection = () => {
  return (
    <div className={styles.container}>
      <SectionWidth className={styles.display}>
        <div className={styles.text}>
          <h2>Trade On The Go.</h2>
          <p>
            Buy and sell Pi in minutes... Get push notifications about your
            orders to keep you in the know of what is going on behind the scenes
          </p>
          <Button text="Go to App" link="/account/dashboard" />
        </div>
        <img
          src={require("../../../assets/tradeSection.png")}
          alt="fundsection"
        />
      </SectionWidth>
    </div>
  );
};

export default TradeSection;
