import React from "react";

import OrdersSection from "./ordersSection/ordersSection";
import { SectionWidth } from "../sectionWidth/sectionWidth";
import { Navigate, Route, Routes } from "react-router";
import CreateAd from "./createAd/createAd";
import EditAd from "./editAd/editAd";

const Ads = () => {
  return (
    <SectionWidth>
      <Routes>
        <Route path="" exact element={<OrdersSection />} />
        <Route path="create/:type" exact element={<CreateAd />} />
        <Route path="edit/:id" exact element={<EditAd />} />

        <Route path={"*"} element={<Navigate replace to="/account/ads" />} />
      </Routes>
    </SectionWidth>
  );
};

export default Ads;
