import React from "react";
import styles from "./lightSection.module.css";
import { SectionWidth } from "../sectionWidth/sectionWidth";

const LightSection = () => {
  return (
    <SectionWidth>
      <div className={styles.container}>
        <img src={require("../../../assets/light.png")} alt="" />
        <h2>Trading Pi has never been easier</h2>
        <p>
          Signup {"-->"} Buy {"-->"} Sell {"-->"} Widthdraw{" "}
        </p>
      </div>
    </SectionWidth>
  );
};

export default LightSection;
