import { apiRequest } from "../../helpers/connections";
import { authSliceActions } from "../../store/auth/auth";
import { controlActions } from "../../store/controls/controls";
import { infoActions } from "../../store/info/info";
import { notificationActions } from "../../store/notification/notification";

export const getBalance = async (dispatch, auth) => {
  try {
    const data = await apiRequest("user/balance", undefined, undefined, auth);
    dispatch(authSliceActions.setBalance(data.balance[0]));
  } catch (error) {
    console.log(error);
  }
};

export const pidata = async (dispatch) => {
  try {
    const data = await apiRequest("transactions/pidata", undefined, undefined);
    dispatch(infoActions.setPrice(data.data));
  } catch (error) {
    console.log(error);
  }
};

export const createWithdrawal = async (dispatch, navigate, form, auth) => {
  dispatch(notificationActions.setNotify(true));

  const body = {
    amount: form.get("amount"),
    receiver: form.get("receiver"),
    token: form.get("token"),
    type: "withdrawal",
  };
  try {
    const data = await apiRequest("transactions", body, "POST", auth);
    dispatch(notificationActions.setMessage(data.message));
    dispatch(controlActions.setWithdrawal(false));
    navigate("/account");
  } catch (error) {
    if (error?.info?.error?.status === 422) {
      dispatch(notificationActions.setMessage(error?.info?.error?.message));
    } else {
      dispatch(notificationActions.setMessage("Something went wrong"));
    }
  }
};

export const getWithdrawalCode = async (dispatch, auth) => {
  dispatch(notificationActions.setNotify(true));

  try {
    const data = await apiRequest(
      "transactions/withdrawtoken",
      undefined,
      undefined,
      auth
    );
    dispatch(notificationActions.setMessage(data.message));
  } catch (error) {
    if (error?.info?.error?.status === 422) {
      dispatch(notificationActions.setMessage(error?.info?.error?.message));
    } else {
      dispatch(notificationActions.setMessage("Something went wrong"));
    }
  }
};

export const getTansactions = async (auth) => {
  try {
    const data = await apiRequest("transactions", undefined, undefined, auth);
    return data.transactions;
  } catch (error) {
    return [];
  }
};

export const genWall = async (dispatch, navigate, auth) => {
  try {
    dispatch(notificationActions.setNotify(true));

    await apiRequest("transactions/gen-address", undefined, undefined, auth);
    dispatch(notificationActions.setNotify(false));
    dispatch(controlActions.setDeposit(false));
    navigate("/account");
  } catch (error) {
    if (error?.info?.error?.status === 422) {
      dispatch(notificationActions.setMessage(error?.info?.error?.message));
    } else {
      dispatch(notificationActions.setMessage("Something went wrong"));
    }
  }
};
