function numberToTwoDecimals(num) {
  if (num) {
    var with2Decimals = num.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
    return Number(with2Decimals);
  } else {
    return 0;
  }
}

export const fee = (amount) => {
  if (typeof amount !== "number") {
    return 0;
  }
  let fee;
  if (amount < 10000) {
    //     <$10,000
    // 1% capped at $100
    fee = 0.01 * amount;
    return numberToTwoDecimals(fee);
  } else if (amount < 100000) {
    //     <$100,000
    // 0.95% capped at $1500
    fee = 0.0095 * amount;
    return numberToTwoDecimals(fee);
  } else if (amount < 500000) {
    //     <$500,000
    // 0.9% capped at $5000
    fee = 0.009 * amount;
    return numberToTwoDecimals(fee);
  } else {
    //     >$500,000
    // 0.85% capped at $10000
    fee = 0.0085 * amount;
    return numberToTwoDecimals(fee);
  }
};

export const verifiedMerchantFee = (amount) => {
  return fee(amount) / 2;
};
