import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

import styles from "./sidebar.module.css";
import Blinking from "../../blinking/blinking";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../auth/authhelper";

const Sidebar = () => {
  const { sidebar } = useSelector((state) => state.control);
  const authState = useSelector((state) => state.auth.loggedIn);

  const [path, setPath] = useState("");
  const location = useLocation();
  useEffect(() => {
    setPath(location.pathname);
  }, [location.pathname]);

  //logout
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleLogout = () => {
    logout(dispatch, navigate, authState);
  };
  return (
    <div className={`${styles.container} ${sidebar ? styles.show : undefined}`}>
      <h2>Pi_GOX</h2>
      <div className={styles.items}>
        <li>
          <img
            src={
              path.includes("dashboard")
                ? require("../../../assets/accountA.png")
                : require("../../../assets/account.png")
            }
            alt="account"
          />
          <NavLink
            className={({ isActive }) => (isActive ? styles.active : undefined)}
            to="/account/dashboard"
          >
            Dashboard
          </NavLink>
        </li>
        <li>
          <img
            src={
              path.includes("orders")
                ? require("../../../assets/ordersA.png")
                : require("../../../assets/orders.png")
            }
            alt="orders"
          />
          <NavLink
            className={({ isActive }) => (isActive ? styles.active : undefined)}
            to="/account/orders"
          >
            Orders
            <Blinking size="small" />
          </NavLink>
        </li>
        <li>
          <img
            src={
              path.includes("ads")
                ? require("../../../assets/adsA.png")
                : require("../../../assets/ads.png")
            }
            alt="ads"
          />
          <NavLink
            className={({ isActive }) => (isActive ? styles.active : undefined)}
            to="/account/ads"
          >
            Ads
          </NavLink>
        </li>
        <li>
          <img
            src={
              path.includes("settings")
                ? require("../../../assets/settingsA.png")
                : require("../../../assets/settings.png")
            }
            alt="settings"
          />
          <NavLink
            className={({ isActive }) => (isActive ? styles.active : undefined)}
            to="/account/settings"
          >
            Settings
          </NavLink>
        </li>
        <li className={styles.logout} onClick={handleLogout}>
          <img src={require("../../../assets/logout.png")} alt="logout" />
          <p>Logout</p>
        </li>
      </div>
    </div>
  );
};

export default Sidebar;
