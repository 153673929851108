import React from "react";
import styles from "./verificationSection.module.css";
import Button from "../button/button";
import { SectionWidth } from "../sectionWidth/sectionWidth";
const VerificationSection = () => {
  return (
    <div className={styles.container}>
      <SectionWidth className={styles.display}>
        <img
          src={require("../../../assets/verificationsection.png")}
          alt="fundsection"
        />
        <div className={styles.text}>
          <h2>Get Verified</h2>
          <p>
            An optional requirement is to submit your KYC to put you in league
            with top users of Pi_GOX
          </p>
          <Button text="Go to App" link="/account/dashboard" />
        </div>
      </SectionWidth>
    </div>
  );
};

export default VerificationSection;
