import React from "react";

import styles from "./dashboard.module.css";
import { SectionWidth } from "../sectionWidth/sectionWidth";
import BalanceCard from "./balanceCard/balanceCard";
import PriceCard from "./priceCard/priceCard";
import TransTable from "./transTable/transTable";
import Withdraw from "./withdraw/withdraw";
import Deposit from "./deposit/deposit";

const Account = () => {
  return (
    <>
      <SectionWidth>
        <Withdraw />
        <Deposit />

        <div className={styles.cardGrid}>
          <BalanceCard />
          <PriceCard />
        </div>
        <TransTable />
      </SectionWidth>
    </>
  );
};

export default Account;
