import React from "react";
import { useNavigate } from "react-router";

import styles from "./button.module.css";

const Button = ({ text, link }) => {
  const navigate = useNavigate();
  return (
    <button className={styles.button} onClick={() => navigate(link)}>
      {text}
    </button>
  );
};

export default Button;
