import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import Sparkline from "../../../sparkline/sparkline";

import styles from "./editAd.module.css";
import { deleteAd, editAd, getAdById } from "../adshelper";
import { useDispatch, useSelector } from "react-redux";
import numberWithCommas from "../../../../helpers/commaSeperator";

const EditAd = () => {
  const params = useParams();
  const id = params.id;

  const [available, setAvailable] = useState(0);
  const [min, setMin] = useState(0);
  const [ad, setAd] = useState({});
  const [price, setPrice] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authState = useSelector((state) => state.auth.loggedIn);
  const { balance } = useSelector((state) => state.auth);

  const handleEditAd = (e) => {
    e.preventDefault();
    const form = new FormData(e.target);

    editAd(dispatch, form, navigate, ad, authState);
  };

  const handleDeleteAd = () => {
    deleteAd(dispatch, navigate, ad, authState);
  };

  useEffect(() => {
    let abortController;
    (async function () {
      abortController = new AbortController();
      const res = await getAdById(dispatch, navigate, id, authState);
      const data = res.ad[0];
      setAd(data);
      setMin(data.min);
      setPrice(data.price);
      setAvailable(data.available);
    })();
    return () => abortController.abort();
  }, [authState, dispatch, id, navigate]);
  return (
    <div className={styles.container}>
      <div>
        <div onClick={() => navigate("/account/ads")}>
          <img src={require("../../../../assets/arrL.png")} alt="arr" />
          <p>Back</p>
        </div>
        {ad.type && (
          <h3>
            Edit {ad.type[0].toUpperCase() + ad.type.slice(1, ad.type.length)}{" "}
            Ad
          </h3>
        )}
      </div>
      <div>
        <div className={styles.info}>
          <Sparkline />
        </div>
        <div className={styles.form}>
          <div>
            <span>Available</span>
            {ad.type === "buy" ? "USDT: " : "Pi"}
            {ad.type === "buy" && numberWithCommas(balance?.available)}
          </div>
          <form onSubmit={handleEditAd}>
            <input
              className={styles.input}
              onChange={(e) => setAvailable(e.target.value)}
              type="tel"
              name="available"
              value={available}
              required
            />
            <label>Min buy</label>
            <input
              className={styles.input}
              onChange={(e) => setMin(e.target.value)}
              type="tel"
              name="min"
              value={min}
              required
            />

            <label>Price</label>

            <input
              className={styles.input}
              onChange={(e) => setPrice(e.target.value)}
              type="tel"
              name="price"
              value={price}
              required
            />

            <input
              className={ad.type === "buy" ? styles.buy : styles.sell}
              type="submit"
              value="Update"
            />
          </form>
          <button onClick={handleDeleteAd} className={styles.delete}>
            Delete Ad
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditAd;
