import React, { useState } from "react";
import styles from "./card.module.css";

const Card = ({ data }) => {
  const [open, setopen] = useState(false);
  return (
    <div className={`${styles.card} ${open ? styles.show : ""}`}>
      <div onClick={() => setopen((state) => !state)}>
        <p>{data.question}</p>
        <img src={require("../../../../assets/hdarr.png")} alt="faq" />
      </div>
      <p>{data.answer}</p>
    </div>
  );
};

export default Card;
