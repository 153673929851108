import React, { useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";

import styles from "./login.module.css";
import { login } from "../authhelper";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ref = useRef();
  const handleLogin = async (e) => {
    e.preventDefault();
    const form = new FormData(e.target);
    const token = ref.current.getValue();
    if (token) {
      ref.current.reset();
      form.append("response", token);
      login(dispatch, form, navigate);
    }
  };

  return (
    <div className={styles.container}>
      <h3>Welcome Back</h3>
      <form onSubmit={handleLogin}>
        <span className={styles.inform}>
          If you signed up for waitlist, use the password reset page to get your
          password
        </span>
        <input type="email" placeholder="Email Address" name="email" required />
        <input
          type="password"
          placeholder="Password"
          name="password"
          required
        />
        <ReCAPTCHA
          ref={ref}
          sitekey="6LcWCeEkAAAAAMavUV2owwFsoumnLqS51JZgK2O-"
        />

        <input type="submit" value="Log in" />
      </form>
      <span onClick={() => navigate("/auth/reset")}>Need help loging in?</span>

      <div className={styles.prompt}>
        <span>Don’t have an account?</span>
        <span onClick={() => navigate("/auth/signup")}>Create an account</span>
      </div>
    </div>
  );
};

export default Login;
