import React from "react";

import styles from "./transCard.module.css";
import CopyToClipboard from "react-copy-to-clipboard";
import { dateToText } from "../../../../../helpers/date";

const TransCard = ({ data }) => {
  const date = dateToText(data.date);
  return (
    <div className={styles.container}>
      <div>
        <span>ID</span>
        <div className={styles.copy}>
          <p>{data.harsh.slice(0, 8)}...</p>
          <CopyToClipboard text={data.harsh} onCopy={() => alert("copied")}>
            <img src={require("../../../../../assets/copy.png")} alt="copy" />
          </CopyToClipboard>
        </div>
      </div>
      <div>
        <span>Transaction</span>
        <div>
          <img
            src={
              data.type === "deposit"
                ? require("../../../../../assets/deptrans.png")
                : require("../../../../../assets/withtrans.png")
            }
            alt={data.type}
          />
          <p>{data.type}</p>
        </div>
      </div>
      <div>
        <span>Amount</span>
        <div>
          <p>{data.amount}</p>
          <span>USDT</span>
        </div>
      </div>
      <div>
        <span>Date</span>
        <p>{date}</p>
      </div>
      <div>
        <span>Status</span>
        <p>
          <img
            src={
              data.status === "completed"
                ? require("../../../../../assets/done.png")
                : data.status === "pending"
                ? require("../../../../../assets/pending.png")
                : require("../../../../../assets/tcancelled.png")
            }
            alt="status"
          />
        </p>
      </div>
    </div>
  );
};

export default TransCard;
