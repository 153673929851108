import React from "react";

import styles from "./header.module.css";
import { useNavigate } from "react-router";
const Header = ({ active, setactive }) => {
  const navigate = useNavigate();
  return (
    <div className={styles.container}>
      <div>
        <button
          onClick={() => setactive("active")}
          className={active === "active" ? styles.active : undefined}
        >
          Active Orders
        </button>
        <button
          onClick={() => setactive("buy")}
          className={active === "buy" ? styles.active : undefined}
        >
          Buy Ads
        </button>
        <button
          onClick={() => setactive("sell")}
          className={active === "sell" ? styles.active : undefined}
        >
          Sell Ads
        </button>
      </div>
      {active !== "active" && (
        <div onClick={() => navigate(`/account/ads/create/${active}`)}>
          <img
            src={require("../../../../../assets/plus.png")}
            alt="create ads"
          />
          <p>Create {active === "buy" ? "Buy" : "Sell"} Ad</p>
        </div>
      )}
    </div>
  );
};

export default Header;
