import React from "react";

import styles from "./header.module.css";
const Header = ({ active, setactive }) => {
  return (
    <div className={styles.container}>
      <div>
        <button
          onClick={() => setactive(true)}
          className={active ? styles.active : undefined}
        >
          Profile
        </button>
        <button
          onClick={() => setactive(false)}
          className={!active ? styles.active : undefined}
        >
          Verification
        </button>
      </div>
      <div>
        <img src={require("../../../../assets/customer.png")} alt="customer" />
        <p>Customer care</p>
      </div>
    </div>
  );
};

export default Header;
