import React from "react";

import styles from "./setPassword.module.css";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { reset } from "../authhelper";
const SetPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSetpass = (e) => {
    e.preventDefault();
    const form = new FormData(e.target);

    reset(dispatch, form, navigate);
  };

  return (
    <div className={styles.container}>
      <h3>Set New Password</h3>
      <form onSubmit={handleSetpass}>
        <input
          type="text"
          placeholder="One-Time password"
          name="token"
          required
        />
        <input
          type="password"
          placeholder="New Password"
          name="password"
          required
        />

        <p>
          By clicking <strong>Set Password</strong>, you agree to our{" "}
          <strong>Terms</strong> and <strong>Conditions</strong>.
        </p>
        <input type="submit" value="Set Password" />
      </form>
      <div className={styles.prompt}>
        <span>Remember your passeword?</span>
        <span onClick={() => navigate("/auth/login")}>Log in</span>
      </div>
    </div>
  );
};

export default SetPassword;
