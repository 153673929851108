import React from "react";

import numberWithCommas from "../../../../../helpers/commaSeperator";

import styles from "./adsCard.module.css";
import { useNavigate } from "react-router";

const AdsCard = ({ data }) => {
  const navigate = useNavigate();
  return (
    <div className={styles.container}>
      <div>
        <span>Price</span>
        <p>{data.price} USDT</p>
      </div>

      <div>
        <span>Min {data.type.toLowerCase() === "buy" ? "Buy" : "Sell"}</span>
        <div className={styles.min}>
          <p>{data.min}</p>
          <span>Pi</span>
        </div>
      </div>
      <div>
        <span>Available</span>
        <p>
          {numberWithCommas(data.available)}{" "}
          {data.type.toLowerCase() === "buy" ? "USDT" : "Pi"}
        </p>
      </div>
      <div>
        <button
          onClick={() => navigate(`/account/ads/edit/${data.ad_id}`)}
          className={styles.buy}
        >
          Edit ad
        </button>
      </div>
    </div>
  );
};

export default AdsCard;
