import { SectionWidth } from "../sectionWidth/sectionWidth";
import { Navigate, Route, Routes } from "react-router";
import OrdersSection from "./ordersSection/ordersSection";
import History from "./history/history";
import Trade from "./trade/trade";
import SingleOrder from "./singleOrder/singleOrder";

const Orders = () => {
  return (
    <SectionWidth>
      <Routes>
        <Route path="" exact element={<OrdersSection />} />
        <Route path="id/:id" exact element={<SingleOrder />} />
        <Route path="history" exact element={<History />} />
        <Route path="trade/:id" exact element={<Trade />} />
        <Route path={"*"} element={<Navigate replace to="/account/orders" />} />
      </Routes>
    </SectionWidth>
  );
};

export default Orders;
