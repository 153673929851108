import React, { useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";

import styles from "./signup.module.css";
import { useNavigate, useParams } from "react-router";
import { useDispatch } from "react-redux";
import { signup } from "../authhelper";

const Signup = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const ref = useRef();
  const [pass, setpass] = useState("password");

  const handleSignup = (e) => {
    e.preventDefault();
    const form = new FormData(e.target);

    const token = ref.current.getValue();
    if (token) {
      form.append("response", token);
      ref.current.reset();
      signup(dispatch, form, navigate, params);
    }
  };

  return (
    <div className={styles.container}>
      <h3>Now, Let’s get started</h3>
      <form onSubmit={handleSignup}>
        <input type="email" placeholder="Email Address" name="email" required />
        <input type="text" placeholder="Name" name="name" required />
        <div className={styles.pass}>
          <input type={pass} placeholder="Password" name="password" required />
          <img
            onClick={() =>
              setpass((prevState) => (pass === "text" ? "password" : "text"))
            }
            src={
              pass === "password"
                ? require("../../../assets/priceoff.png")
                : require("../../../assets/priceon.png")
            }
            alt="price"
          />
        </div>

        <input
          type="text"
          placeholder="Pi Wallet Address"
          name="wallet"
          required
        />

        <span>NOTE: Pi Wallet must be a Verified Mainet Wallet Address</span>
        <p>
          By clicking <strong>Sign up</strong>, you agree to our{" "}
          <strong>Terms</strong> and <strong>Conditions</strong>.
        </p>
        <ReCAPTCHA
          ref={ref}
          sitekey="6LcWCeEkAAAAAMavUV2owwFsoumnLqS51JZgK2O-"
        />
        <input type="submit" value="Sign Up" />
      </form>
      <div className={styles.prompt}>
        <span>Already have an account?</span>
        <span onClick={() => navigate("/auth/login")}>Log in</span>
      </div>
    </div>
  );
};

export default Signup;
