import React, { useEffect, useState } from "react";

import styles from "./blinking.module.css";

const Blinking = ({ size }) => {
  const [blink, setblink] = useState(false);
  useEffect(() => {
    const interval = setInterval(() => {
      setblink((prevstate) => !prevstate);
    }, 600);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <span
      className={`${styles.dot} ${styles[size]} ${
        blink ? styles.active : undefined
      }`}
    ></span>
  );
};

export default Blinking;
