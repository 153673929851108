import React, { useState } from "react";

import { SectionWidth } from "../sectionWidth/sectionWidth";
import Header from "./header/header";
import Profile from "./profile/profile";
import Verification from "./verification/verification";

const Settings = () => {
  const [active, setactive] = useState(true);

  return (
    <SectionWidth>
      <Header active={active} setactive={setactive} />
      {active ? <Profile /> : <Verification />}
    </SectionWidth>
  );
};

export default Settings;
